import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { adminRequest } from "../../../utils/requestMethods";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { BASE_URL } from "../../../utils/config";

const CreateResource = () => {
  const initialFormData = {
    title: "",
    desc: "",
    tags: "",
    url: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();
    formDataToSend.append("file", formData.file);

    formDataToSend.append(
      "resource",

      JSON.stringify({
        title: formData.title,
        desc: formData.desc,
        tags: formData.tags,
      })
    );

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/resource/create`, formDataToSend),
        {
          pending: "Processing your request",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fields = [
    {
      name: "title",
      label: "Title",
      type: "text",
      value: formData.title,
      onChange: handleChange,
    },
    {
      name: "tags",
      label: "Tags",
      type: "text",
      value: formData.tags,
      onChange: handleChange,
    },
    {
      name: "url",
      label: "File",
      type: "file",
      value: formData.url,
      onChange: handleImageChange,
    },
    {
      name: "desc",
      label: "Description",
      type: "textarea",
      value: formData.desc,
      onChange: handleChange,
    },
  ];

  return (
    <div className="createResourceContainer">
      <CustomForm
        header="Create Resource"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Create Resource"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateResource;
