import React, { useEffect, useState } from "react";
import "./ViewEvent.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRequest } from "../../../utils/requestMethods";
import Loader from "../../../components/Loader/Loader";
import { BASE_URL, IMG_URL } from "../../../utils/config";
import { FaArrowLeftLong } from "react-icons/fa6";
import { MdOutlineDateRange } from "react-icons/md";
import { IoTimeSharp } from "react-icons/io5";
import { IoLocationSharp } from "react-icons/io5";
import { toast } from "react-toastify";

const ViewEvent = () => {
  const locations = useLocation();
  const navigate = useNavigate();
  const activeURL = locations.pathname.split("/")[3];
  const [data, setData] = useState(null);

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await adminRequest.post(`${BASE_URL}/event/view`, {
          slug: activeURL,
        });
        setData(response.data.data);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    fetchEvent();
  }, [activeURL]);

  if (!data) {
    return <Loader />;
  }

  return (
    <div className="viewEventContainer">
      <div className="eventContents">
        <div className="heading">
          <FaArrowLeftLong className="backIcon" onClick={handleBackClick} />
          <span className="name">{data.name}</span>
          <span className="status">{data.eventStatus}</span>
        </div>
        <div className="body">
          <span className="description">{data.description}</span>
          <div className="details">
            <div className="dateContainer">
              <span className="icon">
                <MdOutlineDateRange />
              </span>
              <span className="eventDate">{data.eventDate}</span>
            </div>
            <div className="timeContainer">
              <span className="icon">
                <IoTimeSharp />
              </span>
              <span className="eventTime">{data.eventTime}</span>
            </div>
            <div className="locationContainer">
              <span className="icon">
                <IoLocationSharp />
              </span>
              <span className="location">{data.location}</span>
            </div>
          </div>
          <div className="posterContainer">
            <img
              src={`${IMG_URL}${data.eventPoster}`}
              alt="event poster"
              className="poster"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewEvent;
