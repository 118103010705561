import React, { useEffect, useState } from "react";
import "./ViewFaculty.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL, IMG_URL } from "../../../utils/config";
import Loader from "../../../components/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEnvelope, FaPhoneAlt, FaEye, FaEyeSlash } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaGraduationCap } from "react-icons/fa6";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import Swal from "sweetalert2";

const ViewFaculty = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const activeURL = location.pathname.split("/")[3];
  const [refresh, setRefresh] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const maskContent = (content) => {
    return isVisible ? content : content.replace(/./g, "*");
  };
  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  useEffect(() => {
    const fetchFaculty = async () => {
      try {
        const response = await adminRequest.post(
          `${BASE_URL}/faculty/viewOne`,
          {
            uuid: activeURL,
          }
        );
        setData(response.data);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    fetchFaculty();
  }, [activeURL, refresh]);

  const handleSendResendConfirmation = async (e) => {
    e.preventDefault();
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/faculty/resendMail`, {
          uuid: activeURL,
        }),
        {
          pending: "Processing your request",
        }
      );
      setRefresh(!refresh);
      Swal.fire({
        title: "Success",
        text: `${response.data.message}`,
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "ERROR!",
        text: `${error.response.data.message}`,
        icon: "error",
      });
    }
  };
  const handleFacultyPasswordReset = async (e) => {
    e.preventDefault();
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/faculty/resetPassword`, {
          uuid: activeURL,
        }),
        {
          pending: "Processing your request",
        }
      );
      setRefresh(!refresh);
      Swal.fire({
        title: "Success",
        text: `${response.data.message}`,
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "ERROR!",
        text: `${error.response.data.message}`,
        icon: "error",
      });
    }
  };

  updateAuthToken();

  if (!data || !data.faculty) {
    return <Loader />;
  }

  return (
    <div className="facultyDetailsContainer">
      <div className="facultyDetailsContents">
        <div className="facultyDetailsHeader">
          <span>
            <FaArrowLeftLong className="backIcon" onClick={handleBackClick} />
            Faculty Details
          </span>
          <span onClick={toggleVisibility} className="visibilityToggle">
            {isVisible ? (
              <FaEye title="Hide Sensitive Data" />
            ) : (
              <FaEyeSlash title="View Sensitive Data" />
            )}
          </span>
        </div>
        {data && data.faculty ? (
          <>
            <div className="body">
              <div className="left">
                <div className="profileImgContainer">
                  <img
                    src={`${IMG_URL}${data.faculty.profile_pic}`}
                    alt="profileImg"
                    className="profileImg"
                  />
                </div>
                <div className="facultyMemberDetailsContainer">
                  <span className="fullName">{data.faculty.name}</span>
                  <span className="status">{data.faculty.status}</span>
                  <div className="qualificationContainer">
                    <span className="icon">
                      <FaGraduationCap />
                    </span>
                    <span className="qualification">
                      {data.faculty.qualification}
                    </span>
                  </div>
                  <div className="specializationContainer">
                    <span className="icon">
                      <LiaChalkboardTeacherSolid />
                    </span>
                    <span className="specialization">
                      {data.faculty.specialization}
                    </span>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="headerRight">Contact</div>
                <div className="emailContainer">
                  <span className="icon">
                    <FaEnvelope />
                  </span>
                  <span className="email">
                    {maskContent(data.faculty.email)}
                  </span>
                </div>
                <div className="phoneContainer">
                  <span className="icon">
                    <FaPhoneAlt />
                  </span>
                  <span className="phone">
                    {maskContent(data.faculty.mobileNumber)}
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
      <div className="bottom">
        <button
          type="button"
          className="bottom-btn"
          onClick={handleFacultyPasswordReset}
        >
          Send Password Reset Link
        </button>
        <button
          type="button"
          className="bottom-btn"
          onClick={handleSendResendConfirmation}
        >
          Resend Confirmation Mail
        </button>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default ViewFaculty;
