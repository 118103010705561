import React, { useEffect, useState } from "react";
import NotFound from "../../../components/NotFound/NotFound";
import { adminRequest } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
import Swal from "sweetalert2";
import List from "../../../components/List/List";

const CourseList = () => {
  const headers = [
    "Name",
    "No. of Semester",
    "No. of Subject",
    "Duration",
    "Seat",
    "Stream",
    "Status",
  ];
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await adminRequest.get(`${BASE_URL}/course/getAll`);
      const fetchedRows = response.data.data.map((e) => ({
        displayData: [
          e.name,
          e.semester_count,
          e.subject_count,
          e.duration,
          e.seat,
          e.stream,
          e.status,
        ],
        uuid: e.uuid,
        status: e.status,
      }));
      setRows(fetchedRows);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleBlock = async (uuid) => {
    Swal.fire({
      title: "Are you sure you want to block this course?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Block",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(`${BASE_URL}/course/block`, {
            uuid: uuid,
          });
          if (response.data.success == true) {
            Swal.fire({
              title: "Blocked!",
              text: `${response.data.message}`,
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Failed!",
              text: `${response.data.message}`,
              icon: "error",
            });
          }
          fetchData();
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    });
  };

  const handleUnblock = async (uuid) => {
    Swal.fire({
      title: "Are you sure you want to unblock this course?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Unblock",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/course/unblock`,
            {
              uuid: uuid,
            }
          );
          if (response.data.success == true) {
            Swal.fire({
              title: "Unblocked!",
              text: `${response.data.message}`,
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Failed!",
              text: `${response.data.message}`,
              icon: "error",
            });
          }
          fetchData();
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    });
  };
  const getMenuItems = (row) => [
    { link: `view/${row.uuid}`, text: "View" },
    { link: `edit/${row.uuid}`, text: "Edit" },
    row.status !== "BLOCKED"
      ? {
          link: "#",
          text: "Block",
          onClick: (e) => {
            e.preventDefault();
            handleBlock(row.uuid);
          },
        }
      : {
          link: "#",
          text: "Unblock",
          onClick: (e) => {
            e.preventDefault();
            handleUnblock(row.uuid);
          },
        },
  ];

  return (
    <div className="courseListContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <List
            title="Course List"
            createButtonLabel="Create Course"
            headers={headers}
            rows={rows.map((row) => row.displayData)}
            link="create"
            showEyeViewIcon={false}
            showFilterIcon={false}
            getMenuItems={(row) =>
              getMenuItems(rows.find((r) => r.displayData === row))
            }
          />
        </>
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CourseList;
