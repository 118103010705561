import React, { useState } from "react";
import { adminRequest } from "../../../utils/requestMethods";
import { toast, ToastContainer } from "react-toastify";
import { BASE_URL } from "../../../utils/config";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { useNavigate } from "react-router-dom";

const CreateCourse = () => {
  const initialFormData = {
    name: "",
    short_name:"",
    desc: "",
    semester_count: "",
    subject_count: "",
    duration: "",
    seat: "",
    credit_hours: "",
    stream: "",
    image_url:null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image_url: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();

    formDataToSend.append("image_url", formData.image_url);

    formDataToSend.append(
      "course",

      JSON.stringify({
        name: formData.name,
        short_name: formData.short_name,
        desc: formData.desc,
        semester_count: formData.semester_count,
        subject_count: formData.subject_count,
        duration: formData.duration,
        seat: formData.seat,
        credit_hours: formData.credit_hours,
        stream: formData.stream,
      })
    );

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/course/create`, formDataToSend),
        {
          pending: "Processing your request",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fields = [
    {
      name: "name",
      label: "Name",
      type: "text",
      value: formData.name,
      onChange: handleChange,
    },
    {
      name: "short_name",
      label: "Short Name",
      type: "text",
      value: formData.short_name,
      onChange: handleChange,
    },
    {
      name: "semester_count",
      label: "No. of Semester",
      type: "number",
      value: formData.semester_count,
      onChange: handleChange,
    },
    {
      name: "subject_count",
      label: "No. of Subject",
      type: "number",
      value: formData.subject_count,
      onChange: handleChange,
    },
    {
      name: "duration",
      label: "Duration",
      type: "text",
      value: formData.duration,
      onChange: handleChange,
    },
    {
      name: "seat",
      label: "Seat",
      type: "number",
      value: formData.seat,
      onChange: handleChange,
    },
    {
      name: "credit_hours",
      label: "Credit Hours",
      type: "number",
      value: formData.credit_hours,
      onChange: handleChange,
    },
    {
      name: "stream",
      label: "Stream",
      type: "text",
      value: formData.stream,
      onChange: handleChange,
    },
    {
      name: "image_url",
      label: "Image",
      type: "file",
      onChange: handleImageChange,
    },
    {
      name: "desc",
      label: "Description",
      type: "textarea",
      value: formData.desc,
      onChange: handleChange,
    }
  ];

  return (
    <div className="createCourseContainer">
      <CustomForm
        header="Create Course"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Create Course"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateCourse;
