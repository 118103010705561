import React, { useEffect, useState } from "react";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import List from "../../../components/List/List";
import { ToastContainer } from "react-toastify";
import NotFound from "../../../components/NotFound/NotFound";

const ResultList = () => {
  const [rows, setRows] = useState([]);
  const headers = ["Subject Name", "Obtained Marks"];
  const initialFormData = {
    examUuid: "",
    semesterUuid: "",
    studentUuid: "",
    course: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [exams, setExams] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [courses, setCourses] = useState([]);
  const [students, setStudents] = useState([]);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/course/getNameAndId`
        );
        setCourses(response.data.data);
        updateAuthToken();
      } catch (error) {}
    };
    if (formData.course) {
      const fetchSemester = async () => {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/course/getSemestersById`,
            {
              id: formData.course,
            }
          );
          setSemesters(response.data.data);
          updateAuthToken();
        } catch (error) {}
      };
      fetchSemester();
    }
    fetchCourse();
  }, [formData.course]);

  useEffect(() => {
    if (formData.semesterUuid) {
      const fetchStudent = async () => {
        try {
          const response = await adminRequest.post(`${BASE_URL}/student/list`, {
            uuid: formData.semesterUuid,
          });
          setStudents(response.data.data);
          updateAuthToken();
        } catch (error) {}
      };

      fetchStudent();
    }
  }, [formData.semesterUuid]);

  useEffect(() => {
    const fetchExam = async () => {
      try {
        const response = await adminRequest.get(`${BASE_URL}/exam/list`);
        setExams(response.data.data);
        updateAuthToken();
      } catch (error) {}
    };
    fetchExam();
  }, []);

  useEffect(() => {
    const fetchExam = async () => {
      try {
        const response = await adminRequest.get(`${BASE_URL}/exam/list`);
        setExams(response.data.data);
        updateAuthToken();
      } catch (error) {}
    };
    fetchExam();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminRequest.post(`${BASE_URL}/result/list`, {
          examUuid: formData.examUuid,
          semesterUuid: formData.semesterUuid,
          studentUuid: formData.studentUuid,
        });
        const fetchedRows = response.data.data.map((r) => ({
          displayData: [r.subject, r.obtainedMarks],
          uuid: r.uuid,
        }));
        setRows(fetchedRows);
      } catch (error) {
        return <NotFound />;
      }
    };
    fetchData();
  }, [formData.studentUuid]);

  //   const handleFilterSubmit = (e) => {
  //     e.preventDefault();
  //     fetchData();
  //   };

  const filterFields = [
    {
      name: "examUuid",
      label: "Exam",
      type: "select",
      value: formData.examUuid,
      onChange: handleChange,
      options: [
        { label: "Select Exam", value: "" },
        ...exams.map((e) => ({
          label: `${e.name} (${e.examYear})`,
          value: e.uuid,
        })),
      ],
      width: "250px",
    },
    {
      name: "course",
      label: "Course",
      type: "select",
      value: formData.course,
      onChange: handleChange,
      options: [
        { label: "Select Course", value: "" },
        ...courses.map((c) => ({
          label: c.short_name,
          value: c.id,
        })),
      ],
      width: "250px",
    },
    {
      name: "semesterUuid",
      label: "Semester",
      type: "select",
      value: formData.semesterUuid,
      onChange: handleChange,
      options: [
        { label: "Select Semester", value: "" },
        ...semesters.map((s) => ({
          label: s.semester,
          value: s.uuid,
        })),
      ],
      width: "250px",
    },
    {
      name: "studentUuid",
      label: "Student",
      type: "select",
      value: formData.studentUuid,
      onChange: handleChange,
      options: [
        { label: "Select Student", value: "" },
        ...students.map((s) => ({
          label: s.fullName,
          value: s.uuid,
        })),
      ],
      width: "250px",
    },
  ];
  const getMenuItems = (row) => [{ link: `edit/${row.uuid}`, text: "Edit" }];

  return (
    <div className="resultListContainer">
      <div className="resultListContainer">
        <List
          title="Result List"
          createButtonLabel="Create Result"
          headers={headers}
          rows={rows.map((row) => row.displayData)}
          link="create"
          showEyeViewIcon={false}
          showFilterForm={true}
          filterFields={filterFields}
          //   onFilterSubmit={handleFilterSubmit}
          getMenuItems={(row) =>
            getMenuItems(rows.find((r) => r.displayData === row))
          }
        />
        <ToastContainer position="top-center" />
      </div>
    </div>
  );
};

export default ResultList;
