import React, { useEffect, useState } from "react";
import "./Setup.scss";
import { adminRequest, updateAuthToken } from "../../utils/requestMethods";
import { BASE_URL } from "../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import Card from "../../components/Card/Card";
import { Link } from "react-router-dom";
import { PiChalkboardTeacher } from "react-icons/pi";
import { FaBook } from "react-icons/fa";
import { FaRegNewspaper } from "react-icons/fa";
import { MdMoreTime } from "react-icons/md";
import { TfiAnnouncement } from "react-icons/tfi";
import { TfiGallery } from "react-icons/tfi";
import { LuBookMinus, LuNotebookPen } from "react-icons/lu";
import { PiExam } from "react-icons/pi";
import { MdOutlineEventNote } from "react-icons/md";

const iconMapping = {
  PiChalkboardTeacher,
  FaBook,
  FaRegNewspaper,
  MdMoreTime,
  TfiAnnouncement,
  TfiGallery,
  PiExam,
  LuNotebookPen,
  LuBookMinus,
  MdOutlineEventNote,
};
const Setup = () => {
  const [setups, setSetups] = useState([]);

  useEffect(() => {
    const fetchSetup = async () => {
      try {
        const response = await adminRequest.get(`${BASE_URL}/setup/navbar`);
        updateAuthToken();
        setSetups(response.data);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    fetchSetup();
  }, []);

  if (!setups) return <Loader />;
  if (!setups || !setups.navbar) return <Loader />;
  return (
    <div className="setupPageContainer">
      <div className="setupPageContents">
        <div className="headerTitle">Setup</div>
        <div className="setupCard">
          {setups.navbar.map((item, index) => {
            const IconComponent = iconMapping[item.icon];
            return (
              <Link
                to={`${item.link}`}
                style={{ textDecoration: "none" }}
                key={index}
              >
                <Card
                  icon={IconComponent ? <IconComponent /> : null}
                  title={item.name}
                />
              </Link>
            );
          })}
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Setup;
