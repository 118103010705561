import React, { useEffect, useState } from "react";
import "./ViewTestimonial.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL, IMG_URL } from "../../../utils/config";
import Loader from "../../../components/Loader/Loader";
import { FaArrowLeftLong } from "react-icons/fa6";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ViewTestimonial = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const activeURL = location.pathname.split("/")[3];

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  useEffect(() => {
    const fetchTestimonial = async () => {
      try {
        const response = await adminRequest.post(
          `${BASE_URL}/testimonial/view`,
          {
            uuid: activeURL,
          }
        );
        setData(response.data);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    fetchTestimonial();
  }, [activeURL]);

  updateAuthToken();

  if (!data || !data.testimonial) {
    return <Loader />;
  }

  return (
    <div className="testimonialContainer">
      <div className="testimonialContents">
        <div className="testimonialHeader">
          <span>
            <FaArrowLeftLong className="backIcon" onClick={handleBackClick} />
            Testimonial Details
          </span>
        </div>
        {data && data.testimonial ? (
          <>
            <div className="body">
              <div className="left">
                <div className="profileImgContainer">
                  <img
                    src={`${IMG_URL}${data.testimonial.profile_pic}`}
                    alt="profilePic"
                    className="profilePic"
                  />
                </div>
                <div className="testimonialDetailsContainer">
                  <span className="fullName">
                    {data.testimonial.student_name}
                  </span>

                  <span className="status">{data.testimonial.status}</span>
                </div>
              </div>
              <div className="right">
                <span className="testimonial">
                  {data.testimonial.testimonial}
                </span>
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default ViewTestimonial;
