// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebarContainer {
  min-height: calc(100vh - 40px);
  background-color: #ffffff;
  box-sizing: border-box;
  border-right: 1px solid rgba(128, 128, 128, 0.378);
}
.sidebarContainer .sidebarContents {
  display: flex;
  flex-direction: column;
}
.sidebarContainer .sidebarContents .sidebarMenuContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.7rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/Sidebar.scss"],"names":[],"mappings":"AAOA;EACE,8BAAA;EACA,yBARS;EAST,sBAAA;EACA,kDAAA;AANF;AAOE;EACE,aAAA;EACA,sBAAA;AALJ;AAMI;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,WAAA;AAJN","sourcesContent":["//colors\r\n$bg-color: #ffffff;\r\n$primary-color: #264796;\r\n$color-small: rgba(128, 128, 128, 0.915);\r\n$color-dark: #363949;\r\n$color-icon: #2384b4;\r\n$border-color: rgba(128, 128, 128, 0.378);\r\n.sidebarContainer {\r\n  min-height: calc(100vh - 40px);\r\n  background-color: $bg-color;\r\n  box-sizing: border-box;\r\n  border-right: 1px solid $border-color;\r\n  .sidebarContents {\r\n    display: flex;\r\n    flex-direction: column;\r\n    .sidebarMenuContainer {\r\n      display: flex;\r\n      flex-direction: column;\r\n      justify-content: center;\r\n      gap: 0.7rem;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
