// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuContainer {
  position: absolute;
  top: 40px;
  right: 7px;
  background-color: #ffffff;
  border: 1px solid rgba(128, 128, 128, 0.7);
  padding: 10px 20px;
  height: 80px;
  width: 130px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}
.menuContainer .menuContents {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.menuContainer .menuContents .menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  transition: 300ms all ease-in-out;
}
.menuContainer .menuContents .menuItem .icon {
  color: #363949;
}
.menuContainer .menuContents .menuItem .title {
  font-size: 11px;
  font-weight: 600;
  color: #363949;
}
.menuContainer .menuContents .menuItem:hover .title,
.menuContainer .menuContents .menuItem:hover .icon {
  color: #2384b4;
}`, "",{"version":3,"sources":["webpack://./src/components/Menu/Menu.scss"],"names":[],"mappings":"AAOA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,yBAVS;EAWT,0CAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,0CAAA;AANF;AAOE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AALJ;AAMI;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;EACA,qBAAA;EASA,iCAAA;AAZN;AAIM;EACE,cA5BK;AA0Bb;AAIM;EACE,eAAA;EACA,gBAAA;EACA,cAjCK;AA+Bb;AAMQ;;EAEE,cAlCG;AA8Bb","sourcesContent":["$color-dark: #363949;\r\n$bg-color: #ffffff;\r\n$border-color: rgba(128, 128, 128, 0.7);\r\n$shadow-color: rgba(0, 0, 0, 0.3);\r\n$primary-color: #00425a;\r\n$color-icon: #2384b4;\r\n$active-bg-color: #edf2f4d5;\r\n.menuContainer {\r\n  position: absolute;\r\n  top: 40px;\r\n  right: 7px;\r\n  background-color: $bg-color;\r\n  border: 1px solid $border-color;\r\n  padding: 10px 20px;\r\n  height: 80px;\r\n  width: 130px;\r\n  box-shadow: 0px 4px 8px $shadow-color;\r\n  .menuContents {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 6px;\r\n    .menuItem {\r\n      display: flex;\r\n      flex-direction: row;\r\n      align-items: center;\r\n      gap: 10px;\r\n      text-decoration: none;\r\n      .icon {\r\n        color: $color-dark;\r\n      }\r\n      .title {\r\n        font-size: 11px;\r\n        font-weight: 600;\r\n        color: $color-dark;\r\n      }\r\n      transition: 300ms all ease-in-out;\r\n      &:hover {\r\n        .title,\r\n        .icon {\r\n          color: $color-icon;\r\n        }\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
