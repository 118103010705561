import React, { useEffect, useState } from "react";
import { adminRequest } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import NotFound from "../../../components/NotFound/NotFound";
import Loader from "../../../components/Loader/Loader";
import List from "../../../components/List/List";
import { ToastContainer } from "react-toastify";

const ExamList = () => {
  const headers = ["Name", "Exam Year"];
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await adminRequest.get(`${BASE_URL}/exam/list`);
      const fetchedRows = response.data.data.map((e) => ({
        displayData: [e.name, e.examYear],
        uuid: e.uuid,
      }));
      setRows(fetchedRows);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getMenuItems = (row) => [{ link: `edit/${row.uuid}`, text: "Edit" }];

  return (
    <div className="examListContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <List
            title="Exam List"
            createButtonLabel="Create Exam"
            headers={headers}
            rows={rows.map((row) => row.displayData)}
            link="create"
            showEyeViewIcon={false}
            showFilterIcon={false}
            getMenuItems={(row) =>
              getMenuItems(rows.find((r) => r.displayData === row))
            }
          />
        </>
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default ExamList;
