import React, { useEffect, useState } from "react";
import "./SiteContent.scss";
import Card from "../../../components/Card/Card";
import { FaArrowLeftLong, FaMessage } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import Loader from "../../../components/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";

const iconMapping = {
  FaFileAlt,
  FaMessage,
};

const Site = () => {
  const [site, setSite] = useState([]);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    let isMounted = true;
    const fetchSite = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/siteManagement/navbar`
        );
        if (isMounted) {
          updateAuthToken();
          setSite(response.data);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    fetchSite();
    return () => {
      isMounted = false;
    };
  }, []);

  if (!site) return <Loader />;
  if (!site || !site.navbar) return <Loader />;

  return (
    <div className="sitePageContainer">
      <div className="sitePageContents">
        <div className="headerTitle">
          <span className="backIcon" onClick={handleBackClick}>
            <FaArrowLeftLong />
          </span>
          <span className="heading">Site Management</span>
        </div>
        <div className="siteCard">
          {site.navbar.map((item, index) => {
            const IconComponent = iconMapping[item.icon];
            return (
              <Link
                to={`${item.link}`}
                style={{ textDecoration: "none" }}
                key={index}
              >
                <Card
                  icon={IconComponent ? <IconComponent /> : null}
                  title={item.name}
                />
              </Link>
            );
          })}
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Site;
