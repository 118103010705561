import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../utils/config";
import { adminRequest } from "../../../utils/requestMethods";
import NotFound from "../../../components/NotFound/NotFound";
import Loader from "../../../components/Loader/Loader";
import List from "../../../components/List/List";
import {ToastContainer } from "react-toastify";
import Swal from "sweetalert2";

const FacultyList = () => {
  const headers = [
    "Name",
    "Email",
    "Mobile Number",
    "Qualification",
    "Specialization",
    "Hired Date",
    "Status",
  ];
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await adminRequest.get(`${BASE_URL}/faculty/list`);
      const fetchedRows = response.data.faculties.map((e) => ({
        displayData: [
          e.name,
          e.email,
          e.mobileNumber,
          e.qualification,
          e.specialization,
          e.hire_date,
          e.status,
        ],
        uuid: e.uuid,
        status: e.status,
      }));
      setRows(fetchedRows);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const setActive = async (uuid) => {
    Swal.fire({
      title: "Are you sure you want to activate this faculty?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "SetActive",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/faculty/setActive`,
            {
              uuid: uuid,
            }
          );
          Swal.fire({
            title: "Activated!",
            text: `${response.data.message}`,
            icon: "success",
          });

          fetchData();
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };

  const setLeave = async (uuid) => {
    Swal.fire({
      title: "Are you sure you want to set on leave this faculty?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "SetLeave",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/faculty/setLeave`,
            {
              uuid: uuid,
            }
          );

          Swal.fire({
            title: "On Leave!",
            text: `${response.data.message}`,
            icon: "success",
          });
          fetchData();
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };

  const setRetire = async (uuid) => {
    Swal.fire({
      title: "Are you sure you want to retire this faculty?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Retire",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/faculty/retire`,
            {
              uuid: uuid,
            }
          );

          Swal.fire({
            title: "Retired!",
            text: `${response.data.message}`,
            icon: "success",
          });

          fetchData();
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };
  const setTerminate = async (uuid) => {
    Swal.fire({
      title: "Are you sure you want to terminate this faculty?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Terminate",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/faculty/terminate`,
            {
              uuid: uuid,
            }
          );

          Swal.fire({
            title: "Terminated!",
            text: `${response.data.message}`,
            icon: "success",
          });

          fetchData();
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };

  const getMenuItems = (row) => [
    { link: `view/${row.uuid}`, text: "View" },
    { link: `edit/${row.uuid}`, text: "Edit" },
    {
      link: "#",
      text: "Retire",
      onClick: (e) => {
        e.preventDefault();
        setRetire(row.uuid);
      },
    },
    {
      link: "#",
      text: "Terminate",
      onClick: (e) => {
        e.preventDefault();
        setTerminate(row.uuid);
      },
    },
    {
      link: "#",
      text: "SetLeave",
      onClick: (e) => {
        e.preventDefault();
        setLeave(row.uuid);
      },
    },
    {
      link: "#",
      text: "SetActive",
      onClick: (e) => {
        e.preventDefault();
        setActive(row.uuid);
      },
    },
  ];

  return (
    <div className="facultyListContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <List
            title="Faculty List"
            createButtonLabel="Create Faculty"
            headers={headers}
            rows={rows.map((row) => row.displayData)}
            link="create"
            showEyeViewIcon={false}
            showFilterIcon={false}
            getMenuItems={(row) =>
              getMenuItems(rows.find((r) => r.displayData === row))
            }
          />
        </>
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default FacultyList;
