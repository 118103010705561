// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setupPageContainer .setupPageContents .headerTitle {
  font-size: 25px;
  font-weight: 400;
  color: #363949;
  margin-bottom: 1rem;
}
.setupPageContainer .setupPageContents .setupCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/pages/Setup/Setup.scss"],"names":[],"mappings":"AAYI;EACE,eAAA;EACA,gBAAA;EACA,cAXO;EAYP,mBAAA;AAXN;AAaI;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;AAXN","sourcesContent":["//colors\r\n$bg-color: #ffffff;\r\n$primary-color: #264796;\r\n$color-small: rgba(128, 128, 128, 0.915);\r\n$color-dark: #363949;\r\n$color-icon: #2384b4;\r\n$active-bg-color: #edf2f4d5;\r\n$color-white: #f1f1f1f1;\r\n$color-red: #ef233c;\r\n$border-color: rgba(128, 128, 128, 0.378);\r\n.setupPageContainer {\r\n  .setupPageContents {\r\n    .headerTitle {\r\n      font-size: 25px;\r\n      font-weight: 400;\r\n      color: $color-dark;\r\n      margin-bottom: 1rem;\r\n    }\r\n    .setupCard {\r\n      display: flex;\r\n      justify-content: flex-start;\r\n      align-items: center;\r\n      gap: 30px;\r\n      flex-wrap: wrap;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
