import React, { useState } from "react";
import "./UserProfile.scss";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import useFetch from "../../hooks/useFetch";
import { BASE_URL } from "../../utils/config";
import { adminRequest, updateAuthToken } from "../../utils/requestMethods";
import Loader from "../../components/Loader/Loader";
import NotFound from "../NotFound/NotFound";

const UserProfile = () => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const maskContent = (content) => {
    return isVisible ? content : content.replace(/./g, "*");
  };
  const { data, loading } = useFetch(`${BASE_URL}/user/profile`, adminRequest);
  updateAuthToken();
  if (loading) {
    return <Loader />;
  }
  if (!data || !data.data) {
    return <NotFound />;
  }
  const { fullName, address, email, mobileNumber, status, profilePictureName } =
    data.data;
  return (
    <div className="userProfileContainer">
      <div className="userProfileContents">
        <div className="header">
          <span>My Profile</span>
          <span onClick={toggleVisibility} className="visibilityToggle">
            {isVisible ? (
              <FaEye title="Hide Sensitive Data" />
            ) : (
              <FaEyeSlash title="View Sensitive Data" />
            )}
          </span>
        </div>
        <div className="body">
          <div className="left">
            <div className="profileImgContainer">
              <img
                src={
                  profilePictureName
                    ? `${BASE_URL}/path/to/images/${profilePictureName}`
                    : "https://i.pinimg.com/736x/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg"
                }
                alt="profile"
                className="profileImg"
              />
            </div>
            <div className="userDetailsContainer">
              <span className="fullName">{fullName}</span>
              <span className="status">{status}</span>
            </div>
          </div>
          <div className="right">
            <div className="headerRight">Contact</div>
            <div className="emailContainer">
              <span className="icon">
                <FaEnvelope />
              </span>
              <span className="email">{maskContent(email)}</span>
            </div>
            <div className="phoneContainer">
              <span className="icon">
                <FaPhoneAlt />
              </span>
              <span className="phone">{maskContent(mobileNumber)}</span>
            </div>
            <div className="addressContainer">
              <span className="icon">
                <FaMapMarkerAlt />
              </span>
              <span className="address">{maskContent(address)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
