import React, { useEffect, useState } from "react";
import "./StudentList.scss";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import List from "../../../components/List/List";
import NotFound from "../../../components/NotFound/NotFound";

const StudentList = () => {
  const [rows, setRows] = useState([]);
  const headers = [
    "Full Name",
    "Email",
    "Phone Number",
    "Enrollment Date",
    "Status",
  ];
  const initialFormData = {
    enrolledProgram: "",
    enrolledSemester: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [program, setProgram] = useState([]);
  const [semester, setSemester] = useState([]);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchProgram = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/course/getNameAndId`
        );
        setProgram(response.data.data);
        updateAuthToken();
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    if (formData.enrolledProgram) {
      const fetchSemester = async () => {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/course/getSemestersById`,
            {
              id: formData.enrolledProgram,
            }
          );
          setSemester(response.data.data);
          updateAuthToken();
        } catch (error) {
          toast.error(error.response.data.message);
        }
      };
      fetchSemester();
    }
    fetchProgram();
  }, [formData.enrolledProgram]);

  const fetchData = async () => {
    try {
      const response = await adminRequest.post(`${BASE_URL}/student/list`, {
        uuid: formData.enrolledSemester,
      });
      const fetchedRows = response.data.data.map((e) => ({
        displayData: [
          e.fullName,
          e.email,
          e.phoneNumber,
          e.enrollmentDate,
          e.status,
        ],
        uuid: e.uuid,
        status: e.status,
      }));
      setRows(fetchedRows);
    } catch (error) {
      return <NotFound />;
    }
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [formData.enrolledSemester]);

  const filterFields = [
    {
      name: "enrolledProgram",
      label: "Enrolled Program",
      type: "select",
      value: formData.enrolledProgram,
      onChange: handleChange,
      options: [
        { label: "Select Program", value: "" },
        ...program.map((p) => ({
          label: p.short_name,
          value: p.id,
        })),
      ],
    },
    {
      name: "enrolledSemester",
      label: "Semester",
      type: "select",
      value: formData.enrolledSemester,
      onChange: handleChange,
      options: [
        { label: "Select Semester", value: "" },
        ...semester.map((s) => ({
          label: s.semester,
          value: s.uuid,
        })),
      ],
    },
  ];
  const getMenuItems = (row) => [
    { link: `view/${row.uuid}`, text: "View" },
    { link: `edit/${row.uuid}`, text: "Edit" },
  ];

  return (
    <div className="studentListContainer">
      <div className="studentListContainer">
        <List
          title="Student List"
          createButtonLabel="Create Student"
          headers={headers}
          rows={rows.map((row) => row.displayData)}
          link="create"
          showEyeViewIcon={false}
          showFilterForm={true}
          filterFields={filterFields}
          onFilterSubmit={handleFilterSubmit}
          getMenuItems={(row) =>
            getMenuItems(rows.find((r) => r.displayData === row))
          }
        />
        <ToastContainer position="top-center" />
      </div>
    </div>
  );
};

export default StudentList;
