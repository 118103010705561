import React, { useEffect, useState } from "react";
import "./CreateRoutine.scss";
import { useNavigate } from "react-router-dom";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaArrowLeftLong } from "react-icons/fa6";

const CreateRoutine = () => {
  const initialFormData = {
    course: "",
    semester: "",
    day: "",
  };
  const initialRoutineData = {
    subject: "",
    facultyId: "",
    startTime: "",
    endTime: "",
    room: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [routineData, setRoutineData] = useState([initialRoutineData]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [program, setProgram] = useState([]);
  const [semester, setSemester] = useState([]);
  const [subject, setSubject] = useState([]);
  const [faculty, setFaculty] = useState([]);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchProgram = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/course/getNameAndId`
        );
        setProgram(response.data.data);
        updateAuthToken();
      } catch (error) {}
    };
    fetchProgram();
  }, []);

  useEffect(() => {
    if (formData.course) {
      const fetchSemester = async () => {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/course/getSemestersById`,
            { id: formData.course }
          );
          setSemester(response.data.data);
          updateAuthToken();
        } catch (error) {}
      };
      fetchSemester();
    }
  }, [formData.course]);

  useEffect(() => {
    if (formData.semester) {
      const fetchSubject = async () => {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/course/curriculum/subjects`,
            { id: formData.semester }
          );
          setSubject(response.data.data);
          updateAuthToken();
        } catch (error) {}
      };
      fetchSubject();
    }
  }, [formData.semester]);

  useEffect(() => {
    const fetchFaculty = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/faculty/getNameAndId`
        );
        setFaculty(response.data.faculties);
        updateAuthToken();
      } catch (error) {}
    };
    fetchFaculty();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      let routinesData = routineData.map((routine) => ({
        subject: routine.subject,
        facultyId: routine.facultyId,
        startTime: routine.startTime,
        endTime: routine.endTime,
        room: routine.room,
        semester: formData.semester,
        program: formData.course,
        day: formData.day,
      }));
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/routine/create`, {
          routine: routinesData,
        }),
        {
          pending: "Processing your request...",
        }
      );

      toast.success(response.data.message);

      setFormData(initialFormData);
      setRoutineData([initialRoutineData]);
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRoutineChange = (index, field, value) => {
    const newRoutine = [...routineData];
    newRoutine[index][field] = value;
    setRoutineData(newRoutine);
  };

  const addRoutineFields = () => {
    setRoutineData([...routineData, { ...initialRoutineData }]);
  };

  const removeRoutineField = (index) => {
    const newRoutineData = [...routineData];
    newRoutineData.splice(index, 1);
    setRoutineData(newRoutineData);
  };

  return (
    <div className="createRoutineContainer">
      <div className="top">
        <span className="backIcon" onClick={handleBackClick}>
          <FaArrowLeftLong />
        </span>
        <span className="headerTitle">Create Routine</span>
      </div>
      <div className="bottom">
        <form onSubmit={handleSubmit}>
          <div className="courseWrapper">
            <div className="inputGroup">
              <label htmlFor="courses">Course</label>
              <select
                name="course"
                value={formData.course}
                onChange={handleFormChange}
              >
                <option value="" disabled>
                  Select Course
                </option>
                {program.map((option, index) => (
                  <option value={option.id} key={index}>
                    {option.short_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="inputGroup">
              <label htmlFor="semesters">Semester</label>
              <select
                name="semester"
                value={formData.semester}
                onChange={handleFormChange}
              >
                <option value="" disabled>
                  Select Semester
                </option>
                {semester.map((option, index) => (
                  <option value={option.id} key={index}>
                    {option.semester}
                  </option>
                ))}
              </select>
            </div>
            <div className="inputGroup">
              <label htmlFor="day">Day</label>
              <select
                name="day"
                value={formData.day}
                onChange={handleFormChange}
              >
                <option value="" disabled>
                  Select Day
                </option>
                <option value="SUNDAY">Sunday</option>
                <option value="MONDAY">Monday</option>
                <option value="TUESDAY">Tuesday</option>
                <option value="WEDNESDAY">Wednesday</option>
                <option value="THURSDAY">Thursday</option>
                <option value="FRIDAY">Friday</option>
              </select>
            </div>
          </div>
          <div className="routineFields">
            {routineData.map((routine, index) => (
              <div key={index} className="routineFieldGroup">
                <select
                  name="subject"
                  value={routine.subject}
                  onChange={(e) =>
                    handleRoutineChange(index, "subject", e.target.value)
                  }
                >
                  <option value="" disabled>
                    Select Subject
                  </option>
                  {subject.map((option, idx) => (
                    <option value={option.name} key={idx}>
                      {option.name}
                    </option>
                  ))}
                </select>

                <select
                  name="facultyId"
                  value={routine.facultyId}
                  onChange={(e) =>
                    handleRoutineChange(index, "facultyId", e.target.value)
                  }
                >
                  <option value="" disabled>
                    Select Faculty
                  </option>
                  {faculty.map((option, idx) => (
                    <option value={option.id} key={idx}>
                      {option.name}
                    </option>
                  ))}
                </select>

                <input
                  type="time"
                  value={routine.startTime}
                  onChange={(e) =>
                    handleRoutineChange(index, "startTime", e.target.value)
                  }
                />
                <input
                  type="time"
                  value={routine.endTime}
                  onChange={(e) =>
                    handleRoutineChange(index, "endTime", e.target.value)
                  }
                />
                <input
                  type="text"
                  placeholder="Room No"
                  value={routine.room}
                  onChange={(e) =>
                    handleRoutineChange(index, "room", e.target.value)
                  }
                />

                <div className="buttonGroup">
                  {index === routineData.length - 1 && (
                    <button
                      type="button"
                      className="addRoutineButton"
                      onClick={addRoutineFields}
                    >
                      +
                    </button>
                  )}
                  {index === routineData.length - 1 &&
                    routineData.length > 1 && (
                      <button
                        type="button"
                        className="removeRoutineButton"
                        onClick={() => removeRoutineField(index)}
                      >
                        -
                      </button>
                    )}
                </div>
              </div>
            ))}
          </div>
          <div className="submitWrapper">
            <button
              type="submit"
              className="create-btn"
              disabled={isSubmitting}
            >
              Submit
            </button>
            <button
              type="button"
              className="cancel-btn"
              onClick={handleBackClick}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateRoutine;
