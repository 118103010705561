// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sitePageContainer .sitePageContents .headerTitle {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  color: #363949;
}
.sitePageContainer .sitePageContents .headerTitle .backIcon {
  margin-top: 4px;
  font-size: 20px;
  color: #363949;
}
.sitePageContainer .sitePageContents .headerTitle .backIcon:hover {
  cursor: pointer;
}
.sitePageContainer .sitePageContents .headerTitle .heading {
  font-size: 24px;
  font-weight: 400;
  color: #363949;
}
.sitePageContainer .sitePageContents .siteCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/pages/Site/SiteContent/SiteContent.scss"],"names":[],"mappings":"AAYI;EACE,oBAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;EACA,cAbO;AAEb;AAYM;EACE,eAAA;EACA,eAAA;EACA,cAjBK;AAOb;AAWQ;EACE,eAAA;AATV;AAYM;EACE,eAAA;EACA,gBAAA;EACA,cAzBK;AAeb;AAaI;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;AAXN","sourcesContent":["//colors\r\n$bg-color: #ffffff;\r\n$primary-color: #264796;\r\n$color-small: rgba(128, 128, 128, 0.915);\r\n$color-dark: #363949;\r\n$color-icon: #2384b4;\r\n$active-bg-color: #edf2f4d5;\r\n$color-white: #f1f1f1f1;\r\n$color-red: #ef233c;\r\n$border-color: rgba(128, 128, 128, 0.378);\r\n.sitePageContainer {\r\n  .sitePageContents {\r\n    .headerTitle {\r\n      display: inline-flex;\r\n      flex-direction: row;\r\n      align-items: center;\r\n      gap: 30px;\r\n      color: $color-dark;\r\n      .backIcon {\r\n        margin-top: 4px;\r\n        font-size: 20px;\r\n        color: $color-dark;\r\n        &:hover {\r\n          cursor: pointer;\r\n        }\r\n      }\r\n      .heading {\r\n        font-size: 24px;\r\n        font-weight: 400;\r\n        color: $color-dark;\r\n      }\r\n    }\r\n    .siteCard {\r\n      display: flex;\r\n      justify-content: flex-start;\r\n      align-items: center;\r\n      gap: 30px;\r\n      flex-wrap: wrap;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
