import React, { useState } from "react";
import { BASE_URL } from "../../../utils/config";
import { adminRequest } from "../../../utils/requestMethods";
import { toast, ToastContainer } from "react-toastify";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { useNavigate } from "react-router-dom";

const CreateNotice = () => {
  const initialFormData = {
    title: "",
    desc: "",
    date: "",
    file: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();
    formDataToSend.append("file", formData.file);

    formDataToSend.append(
      "notice",

      JSON.stringify({
        title: formData.title,
        desc: formData.desc,
        date: formData.date,
      })
    );

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/notice/create`, formDataToSend),
        {
          pending: "Processing your request",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fields = [
    {
      name: "title",
      label: "Title",
      type: "text",
      value: formData.title,
      onChange: handleChange,
    },

    {
      name: "date",
      label: "Date",
      type: "date",
      value: formData.date,
      onChange: handleChange,
    },
    {
      name: "file",
      label: "File",
      type: "file",
      onChange: handleImageChange,
    },
    {
      name: "desc",
      label: "Description",
      type: "textarea",
      value: formData.desc,
      onChange: handleChange,
    },
  ];

  return (
    <div className="createNoticeContainer">
      <CustomForm
        header="Create Notice"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Create Notice"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateNotice;
