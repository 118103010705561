import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { adminRequest } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { useNavigate } from "react-router-dom";

const CreateFaculty = () => {
  const initialFormData = {
    name: "",
    email: "",
    mobileNumber: "",
    qualification: "",
    specialization: "",
    hire_date: "",
    profile_pic: null,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleImageChange = (e) => {
    setFormData({ ...formData, profile_pic: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();
    formDataToSend.append("profile_pic", formData.profile_pic);
    formDataToSend.append(
      "faculty",
      JSON.stringify({
        name: formData.name,
        email: formData.email,
        mobileNumber: formData.mobileNumber,
        qualification: formData.qualification,
        specialization: formData.specialization,
        hire_date: formData.hire_date,
      })
    );

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/faculty/create`, formDataToSend),
        {
          pending: "Processing your request",
        }
      );
      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const fields = [
    {
      name: "name",
      label: "Name",
      type: "text",
      value: formData.name,
      onChange: handleChange,
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      value: formData.email,
      onChange: handleChange,
    },
    {
      name: "mobileNumber",
      label: "Mobile Number",
      type: "text",
      value: formData.mobileNumber,
      onChange: handleChange,
    },
    {
      name: "qualification",
      label: "Qualification",
      type: "text",
      value: formData.qualification,
      onChange: handleChange,
    },
    {
      name: "specialization",
      label: "Specialization",
      type: "text",
      value: formData.specialization,
      onChange: handleChange,
    },
    {
      name: "hire_date",
      label: "Hire Date",
      type: "date",
      value: formData.hire_date,
      onChange: handleChange,
    },
    {
      name: "profile_pic",
      label: "Profile Picture",
      type: "file",
      onChange: handleImageChange,
    },
  ];

  return (
    <div className="createFacultyContainer">
      <CustomForm
        header="Create Faculty"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Create Faculty"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateFaculty;
