import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import Loader from "../../../components/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import CustomForm from "../../../components/CustomForm/CustomForm";
import "react-toastify/dist/ReactToastify.css";

const EditTestimonial = () => {
  const location = useLocation();
  const activeURL = location.pathname.split("/")[3];
  const initialFormData = {
    student_name: "",
    testimonial: "",
    degree: "",
    start_year: "",
    end_year: "",
    current_job: "",
    current_company: "",
    profile_pic: "",
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [data, setData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const fetchTestimonial = async () => {
      try {
        const response = await adminRequest.post(
          `${BASE_URL}/testimonial/view`,
          {
            uuid: `${activeURL}`,
          }
        );
        if (isMounted) {
          setData(response.data);
          setFormData({
            student_name: response.data.testimonial.student_name,
            testimonial: response.data.testimonial.testimonial,
            degree: response.data.testimonial.degree,
            start_year: response.data.testimonial.start_year,
            end_year: response.data.testimonial.end_year,
            current_job: response.data.testimonial.current_job,
            current_company: response.data.testimonial.current_company,
          });
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };

    fetchTestimonial();
    return () => {
      isMounted = false;
    };
  }, [activeURL]);

  updateAuthToken();

  if (!data || !data.testimonial) {
    return <Loader />;
  }
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/testimonial/update`, {
          uuid: activeURL,
          student_name: formData.student_name,
          testimonial: formData.testimonial,
          degree: formData.degree,
          start_year: formData.start_year,
          end_year: formData.end_year,
          current_job: formData.current_job,
          current_company: formData.current_company,
          profile_pic: formData.profile_pic,
        }),
        {
          pending: "Updating testimonial",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  const fields = [
    {
      name: "student_name",
      label: "Name",
      type: "text",
      value: formData.student_name,
      onChange: handleChange,
    },
    {
      name: "degree",
      label: "Degree",
      type: "text",
      value: formData.degree,
      onChange: handleChange,
    },
    {
      name: "start_year",
      label: "Start Year",
      type: "text",
      value: formData.start_year,
      onChange: handleChange,
    },
    {
      name: "end_year",
      label: "End Year",
      type: "text",
      value: formData.end_year,
      onChange: handleChange,
    },
    {
      name: "current_job",
      label: "Current Job",
      type: "text",
      value: formData.current_job,
      onChange: handleChange,
    },
    {
      name: "current_company",
      label: "Current Company",
      type: "text",
      value: formData.current_company,
      onChange: handleChange,
    },
    {
      name: "testimonial",
      label: "Testimonial",
      type: "textarea",
      value: formData.testimonial,
      onChange: handleChange,
    },

    {
      name: "profile_pic",
      label: "Profile Picture",
      type: "file",
      value: formData.profile_pic,
      onChange: handleChange,
    },
  ];

  return (
    <div className="editTestimonialContainer">
      <CustomForm
        header="Edit Testimonial"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Update"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EditTestimonial;
