import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import Loader from "../../../components/Loader/Loader";
const EditRoutine = () => {
  const location = useLocation();
  const activeURL = location.pathname.split("/")[3];
  const initialFormData = {
    subject: "",
    program: "",
    semester: "",
    day: "",
    startTime: "",
    endTime: "",
    room: "",
    facultyId: "",
  };

  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [data, setData] = useState(initialFormData);
  const [program, setProgram] = useState([]);
  const [semester, setSemester] = useState([]);
  const [subject, setSubject] = useState([]);
  const [faculty, setFaculty] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchProgram = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/course/getNameAndId`
        );
        setProgram(response.data.data);
        updateAuthToken();
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    if (formData.program) {
      const fetchSemester = async () => {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/course/getSemestersById`,
            {
              id: formData.program,
            }
          );
          setSemester(response.data.data);
          updateAuthToken();
        } catch (error) {
          toast.error(error.response.data.message);
        }
      };
      fetchSemester();
    }
    fetchProgram();
  }, [formData.program]);

  useEffect(() => {
    if (formData.semester) {
      const fetchSubject = async () => {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/course/curriculum/subjects`,
            {
              id: formData.semester,
            }
          );
          setSubject(response.data.data);
          updateAuthToken();
        } catch (error) {
          toast.error(error.response.data.message);
        }
      };
      fetchSubject();
    }
  }, [formData.semester]);

  useEffect(() => {
    const fetchFaculty = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/faculty/getNameAndId`
        );
        setFaculty(response.data.faculties);
        updateAuthToken();
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    fetchFaculty();
  }, []);

  useEffect(() => {
    let isMounted = true;
    const fetchRoutine = async () => {
      try {
        const response = await adminRequest.post(`${BASE_URL}/routine/view`, {
          uuid: `${activeURL}`,
        });
        if (isMounted) {
          setData(response.data);
          setFormData({
            subject: response.data.data.subject,
            program: response.data.data.program,
            semester: response.data.data.semester,
            day: response.data.data.day,
            startTime: response.data.data.startTime,
            endTime: response.data.data.endTime,
            room: response.data.data.room,
            facultyId: response.data.data.facultyId,
          });
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };

    fetchRoutine();
    return () => {
      isMounted = false;
    };
  }, [activeURL]);

  updateAuthToken();

  if (!data || !data.data) {
    return <Loader />;
  }
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/routine/update`, {
          uuid: activeURL,
          subject: formData.subject,
          program: formData.program,
          semester: formData.semester,
          day: formData.day,
          startTime: formData.startTime,
          endTime: formData.endTime,
          room: formData.room,
          facultyId: formData.facultyId,
        }),
        {
          pending: "Updating Routine",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  const fields = [
    {
      name: "program",
      label: "Course",
      type: "select",
      value: formData.program,
      onChange: handleChange,
      options: [
        { label: "Select Course", value: "" },
        ...program.map((p) => ({
          label: p.short_name,
          value: p.id,
        })),
      ],
    },
    {
      name: "semester",
      label: "Semester",
      type: "select",
      value: formData.semester,
      onChange: handleChange,
      options: [
        { label: "Select Semester", value: "" },
        ...semester.map((semester) => ({
          label: semester.semester,
          value: semester.id,
        })),
      ],
    },
    {
      name: "subject",
      label: "Subject",
      type: "select",
      value: formData.subject,
      onChange: handleChange,
      options: [
        { label: "Select Subject", value: "" },
        ...subject.map((s) => ({
          label: s.name,
          value: s.name,
        })),
      ],
    },
    {
      name: "day",
      label: "Day",
      type: "select",
      options: [
        { value: "", label: "Select Day" },
        { value: "SUNDAY", label: "Sunday" },
        { value: "MONDAY", label: "Monday" },
        { value: "TUESDAY", label: "Tuesday" },
        { value: "WEDNESDAY", label: "Wednesday" },
        { value: "THURSDAY", label: "Thursday" },
        { value: "FRIDAY", label: "Friday" },
      ],
      value: formData.day,
      onChange: handleChange,
    },
    {
      name: "startTime",
      label: "Start Time",
      type: "time",
      value: formData.startTime,
      onChange: handleChange,
    },
    {
      name: "endTime",
      label: "End Time",
      type: "time",
      value: formData.endTime,
      onChange: handleChange,
    },
    {
      name: "room",
      label: "Room",
      type: "text",
      value: formData.room,
      onChange: handleChange,
    },
    {
      name: "facultyId",
      label: "Faculty",
      type: "select",
      value: formData.facultyId,
      onChange: handleChange,
      options: [
        { label: "Select Faculty", value: "" },
        ...faculty.map((f) => ({
          label: f.name,
          value: f.id,
        })),
      ],
    },
  ];
  return (
    <div className="editRoutineContainer">
      <CustomForm
        header="Edit Routine"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Update"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EditRoutine;
