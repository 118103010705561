// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noRecordContainer .noRecordContents {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50vh;
  width: 100%;
  gap: 20px;
}
.noRecordContainer .noRecordContents .noRecordIconContainer {
  border-radius: 50%;
  background-color: rgba(237, 242, 244, 0.8352941176);
}
.noRecordContainer .noRecordContents .noRecordIconContainer .noRecordIcon {
  padding: 5px;
  height: 80px;
  width: 80px;
  color: #00425a;
}
.noRecordContainer .noRecordContents .noRecordText {
  color: #363949;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/NoRecord/NoRecord.scss"],"names":[],"mappings":"AAQE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;AAPJ;AAQI;EACE,kBAAA;EACA,mDAZY;AAMlB;AAOM;EACE,YAAA;EACA,YAAA;EACA,WAAA;EACA,cArBQ;AAgBhB;AAQI;EACE,cAvBO;EAwBP,gBAAA;AANN","sourcesContent":["//colors\r\n$bg-color: #ffffff;\r\n$primary-color: #00425a;\r\n$color-small: rgba(128, 128, 128, 0.915);\r\n$color-dark: #363949;\r\n$color-icon: #2384b4;\r\n$active-bg-color: #edf2f4d5;\r\n.noRecordContainer {\r\n  .noRecordContents {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    flex-direction: column;\r\n    height: 50vh;\r\n    width: 100%;\r\n    gap: 20px;\r\n    .noRecordIconContainer {\r\n      border-radius: 50%;\r\n      background-color: $active-bg-color;\r\n      .noRecordIcon {\r\n        padding: 5px;\r\n        height: 80px;\r\n        width: 80px;\r\n        color: $primary-color;\r\n      }\r\n    }\r\n    .noRecordText {\r\n      color: $color-dark;\r\n      font-weight: 500;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
