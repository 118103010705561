import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { BASE_URL } from "../../../utils/config";

const EditNews = () => {
  const location = useLocation();
  const activeURL = location.pathname.split("/")[3];
  const initialFormData = {
    title: "",
    contents: "",
    image_url: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [data, setData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEditorChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      contents: value,
    }));
  };

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await adminRequest.post(`${BASE_URL}/news/view`, {
          slug: `${activeURL}`,
        });
        setData(response.data);
        setFormData({
          title: response.data.news.title,
          contents: response.data.news.content,
          image_url: response.data.news.image_url,
        });
      } catch (error) {
        toast.error("Failed to fetch news at the moment");
      }
    };
    fetchNews();
  }, [activeURL]);

  updateAuthToken();

  if (!data || !data.news) {
    return <Loader />;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/news/update`, {
          slug: activeURL,
          title: formData.title,
          contents: formData.contents,
          image: formData.image_url,
        }),
        {
          pending: "Updating News",
        }
      );

      toast.success(response.data.message);
      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  const fields = [
    {
      name: "title",
      label: "Title",
      type: "text",
      value: formData.title,
      onChange: handleChange,
      width: "500px",
    },

    {
      name: "contents",
      type: "textarea",
      value: formData.contents,
      onChange: handleChange,
      width: "500px",
      height: "200px",
    },
  ];

  return (
    <div className="updateNewsContainer">
      <CustomForm
        header="Edit News"
        fields={fields}
        flexDirection="column"
        createButtonLabel="Update"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EditNews;
