import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { BASE_URL } from "../../../utils/config";
import { adminRequest } from "../../../utils/requestMethods";

const CreateNews = () => {
  const initialFormData = {
    title: "",
    contents: "",
    image_url: "",
  };

  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleEditorChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      contents: value.replace(/<\/?p>/g, ""),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/news/create`, {
          title: formData.title,
          content: formData.contents,
          image_url:
            "https://thumbs.dreamstime.com/b/news-woodn-dice-depicting-letters-bundle-small-newspapers-leaning-left-dice-34802664.jpg",
        }),
        {
          pending: "Posting News",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  const fields = [
    {
      name: "title",
      label: "Title",
      type: "text",
      value: formData.title,
      onChange: handleChange,
      width: "500px",
    },
    {
      name: "contents",
      type: "textarea",
      value: formData.contents,
      onChange: handleChange,
      placeHolder: "write content here...",
      width: "500px",
      height: "200px",
    },
  ];

  return (
    <div className="createNewsContainer">
      <CustomForm
        header="Create News"
        fields={fields}
        flexDirection="column"
        createButtonLabel="Post"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateNews;
