import React, { useEffect, useState } from "react";
import "./UserDetail.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { BASE_URL } from "../../../utils/config";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FaEnvelope,
  FaPhoneAlt,
  FaEye,
  FaEyeSlash,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import Swal from "sweetalert2";

const UserDetail = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const activeURL = location.pathname.split("/")[3];
  const [refresh, setRefresh] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const maskContent = (content) => {
    return isVisible ? content : content.replace(/./g, "*");
  };
  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await adminRequest.post(`${BASE_URL}/user/viewUser`, {
          uuid: activeURL,
        });
        setData(response.data);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    fetchUser();
  }, [activeURL, refresh]);

  updateAuthToken();

  if (!data || !data.user) {
    return <Loader />;
  }

  const handleBlock = async () => {
    Swal.fire({
      title: "Are you sure you want to block this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Block",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(`${BASE_URL}/user/block`, {
            uuid: activeURL,
          });

          setRefresh(!refresh);
          Swal.fire({
            title: "Blocked!",
            text: `${response.data.message}`,
            icon: "success",
          });
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };

  const handleUnblock = async () => {
    Swal.fire({
      title: "Are you sure you want to unblock this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Unblock",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(`${BASE_URL}/user/unblock`, {
            uuid: activeURL,
          });
          setRefresh(!refresh);
          Swal.fire({
            title: "Unblocked!",
            text: `${response.data.message}`,
            icon: "success",
          });
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };

  const handleSendResendConfirmation = async (e) => {
    e.preventDefault();
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/user/resendMail`, {
          uuid: activeURL,
        }),
        {
          pending: "Processing your request",
        }
      );
      setRefresh(!refresh);
      Swal.fire({
        title: "Success",
        text: `${response.data.message}`,
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "ERROR!",
        text: `${error.response.data.message}`,
        icon: "error",
      });
    }
  };
  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/user/resetPassword`, {
          uuid: activeURL,
        }),
        {
          pending: "Processing your request",
        }
      );
      setRefresh(!refresh);
      Swal.fire({
        title: "Success",
        text: `${response.data.message}`,
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "ERROR!",
        text: `${error.response.data.message}`,
        icon: "error",
      });
    }
  };

  return (
    <div className="adminDetailsContainer">
      <div className="adminDetailsContents">
        <div className="adminDetailsHeader">
          <span>
            <FaArrowLeftLong className="backIcon" onClick={handleBackClick} />
            Admin User Details
          </span>
          <span onClick={toggleVisibility} className="visibilityToggle">
            {isVisible ? (
              <FaEye title="Hide Sensitive Data" />
            ) : (
              <FaEyeSlash title="View Sensitive Data" />
            )}
          </span>
        </div>
        {data && data.user ? (
          <>
            <div className="body">
              <div className="left">
                <div className="profileImgContainer">
                  <img
                    src={
                      data.user.profile_pic
                        ? `${BASE_URL}/path/to/images/${data.user.profile_pic}`
                        : "https://i.pinimg.com/736x/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg"
                    }
                    alt="profile"
                    className="profileImg"
                  />
                </div>
                <div className="adminUserDetailsContainer">
                  <span className="fullName">{data.user.fullName}</span>
                  <span className="username">
                    {maskContent(`@${data.user.email}`)}
                  </span>
                  <span className="status">{data.user.status}</span>
                </div>
              </div>
              <div className="right">
                <div className="headerRight">Contact</div>
                <div className="emailContainer">
                  <span className="icon">
                    <FaEnvelope />
                  </span>
                  <span className="email">{maskContent(data.user.email)}</span>
                </div>
                <div className="phoneContainer">
                  <span className="icon">
                    <FaPhoneAlt />
                  </span>
                  <span className="phone">
                    {maskContent(data.user.mobileNumber)}
                  </span>
                </div>
                <div className="addressContainer">
                  <span className="icon">
                    <FaMapMarkerAlt />
                  </span>
                  <span className="phone">{data.user.address}</span>
                </div>
              </div>
            </div>
            <div className="bottom">
              <button
                type="button"
                className="bottom-btn"
                onClick={
                  data.user.status === "BLOCKED" ? handleUnblock : handleBlock
                }
              >
                {data.user.status === "BLOCKED" ? "Unblock" : "Block"}
              </button>

              <button
                type="button"
                className="bottom-btn"
                onClick={handleResetPassword}
              >
                Send Password Reset Link
              </button>
              <button
                type="button"
                className="bottom-btn"
                onClick={handleSendResendConfirmation}
              >
                Resend Confirmation Mail
              </button>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default UserDetail;
