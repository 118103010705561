import React, { useEffect, useState } from "react";
import "./ViewGallery.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRequest } from "../../../utils/requestMethods";
import { BASE_URL, IMG_URL } from "../../../utils/config";
import Loader from "../../../components/Loader/Loader";
import { FaArrowLeftLong } from "react-icons/fa6";
import { toast } from "react-toastify";

const ViewGallery = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const activeURL = location.pathname.split("/")[3];
  const [data, setData] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseClick = () => {
    setSelectedImage(null);
  };
  useEffect(() => {
    const fetchGallery = async () => {
      try {
        const response = await adminRequest.post(
          `${BASE_URL}/gallery/viewOne`,
          {
            slug: activeURL,
          }
        );
        setData(response.data);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    fetchGallery();
  }, [activeURL]);

  if (!data) {
    return <Loader />;
  }
  const { data: galleryData } = data;

  if (!galleryData) {
    return <Loader />;
  }

  const { title, desc, images, status } = galleryData;
  return (
    <div className="galleryContainer">
      <div className="galleryContents">
        <div className="galleryHeader">
          <FaArrowLeftLong className="backIcon" onClick={handleBackClick} />
          <span className="title">{title}</span>
          <span className="status">{status}</span>
        </div>
        <div className="description">
          <span>{desc}</span>
        </div>
        <div className="image-list">
          {images.map((image, index) => (
            <div
              className="image-item"
              key={index}
              onClick={() => handleImageClick(`${IMG_URL}${image}`)}
            >
              <img
                src={`${IMG_URL}${image}`}
                alt={`Gallery Image ${index + 1}`}
              />
            </div>
          ))}
        </div>
        {selectedImage && (
          <div className="image-modal" onClick={handleCloseClick}>
            <div
              className="image-modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <img src={selectedImage} alt={selectedImage} />
              <button className="close-button" onClick={handleCloseClick}>
                X
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ViewGallery;
