import React, { useEffect, useState } from "react";
import "./About.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import Loader from "../../../components/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import CustomForm from "../../../components/CustomForm/CustomForm";

const About = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const activeURL = location.pathname.split("/")[3];
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialFormData = {
    title: "",
    content: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/pageContent/about/get`
        );
        setData(response.data);
        setFormData({
          title: response.data.data.title,
          content: response.data.data.content,
        });
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    fetchData();
  }, [activeURL]);

  updateAuthToken();

  if (!data || !data.data) {
    return <Loader />;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/pageContent/about/update`, {
          title: formData.title,
          content: formData.content,
        }),
        {
          pending: "Updating About",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });
      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fields = [
    {
      name: "title",
      label: "Title",
      type: "text",
      value: formData.title,
      onChange: handleChange,
      width: "500px",
      height: "30px",
    },

    {
      name: "content",
      type: "textarea",
      label: "About",
      value: formData.content,
      onChange: handleChange,
      width: "500px",
      height: "200px",
    },
  ];
  return (
    <div className="updateAboutContainer">
      <CustomForm
        header="Edit About"
        fields={fields}
        flexDirection="column"
        createButtonLabel="Update"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default About;
