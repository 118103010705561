// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homepageContainer {
  background-color: #ffffff;
  height: 100vh;
}
.homepageContainer .homepageContents {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.homepageContainer .homepageContents .homepageTop {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 40px;
  z-index: 1000;
}
.homepageContainer .homepageContents .middle {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  height: calc(100vh - 40px);
}
.homepageContainer .homepageContents .middle .homepageContents_left {
  flex: 1 1;
  overflow-y: auto;
  position: sticky;
  top: 40px;
  height: calc(100vh - 40px);
  scrollbar-width: none;
}
.homepageContainer .homepageContents .middle .homepageContents_left::-webkit-scrollbar {
  display: none;
}
.homepageContainer .homepageContents .middle .homepageContents_right {
  margin: 1.5rem;
  flex: 13 1;
  overflow-y: auto;
  height: calc(100vh - 40px - 3rem);
  scrollbar-width: none;
}
.homepageContainer .homepageContents .middle .homepageContents_right::-webkit-scrollbar {
  display: none;
}
.homepageContainer .homepageContents .middle .homepageContents_right .cardArea {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  justify-content: center;
  gap: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Homepage/Homepage.scss"],"names":[],"mappings":"AAGA;EACE,yBAHS;EAIT,aAAA;AAFF;AAGE;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AADJ;AAEI;EACE,aAAA;EACA,sBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AAAN;AAEI;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,0BAAA;AAAN;AACM;EACE,SAAA;EACA,gBAAA;EACA,gBAAA;EACA,SAAA;EACA,0BAAA;EAIA,qBAAA;AAFR;AADQ;EACE,aAAA;AAGV;AACM;EACE,cAAA;EACA,UAAA;EACA,gBAAA;EACA,iCAAA;EAIA,qBAAA;AAFR;AADQ;EACE,aAAA;AAGV;AAAQ;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,uBAAA;EACA,SAAA;AAEV","sourcesContent":["//colors\r\n$bg-color: #ffffff;\r\n$primary-color: #00425a;\r\n.homepageContainer {\r\n  background-color: $bg-color;\r\n  height: 100vh;\r\n  .homepageContents {\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 100%;\r\n    .homepageTop {\r\n      display: flex;\r\n      flex-direction: column;\r\n      position: fixed;\r\n      width: 100%;\r\n      height: 40px;\r\n      z-index: 1000;\r\n    }\r\n    .middle {\r\n      display: flex;\r\n      flex-direction: row;\r\n      margin-top: 40px;\r\n      height: calc(100vh - 40px);\r\n      .homepageContents_left {\r\n        flex: 1;\r\n        overflow-y: auto;\r\n        position: sticky;\r\n        top: 40px;\r\n        height: calc(100vh - 40px);\r\n        &::-webkit-scrollbar {\r\n          display: none;\r\n        }\r\n        scrollbar-width: none;\r\n      }\r\n      .homepageContents_right {\r\n        margin: 1.5rem;\r\n        flex: 13;\r\n        overflow-y: auto;\r\n        height: calc(100vh - 40px - 3rem);\r\n        &::-webkit-scrollbar {\r\n          display: none;\r\n        }\r\n        scrollbar-width: none;\r\n        .cardArea {\r\n          display: flex;\r\n          align-items: center;\r\n          padding: 10px 20px;\r\n          justify-content: center;\r\n          gap: 2rem;\r\n        }\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
