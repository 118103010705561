import React, { useState } from "react";
import { MdOutlineEmail, MdOutlinePassword } from "react-icons/md";
import logo from "../../assets/logo.png";
import "./Login.scss";
import { loginAdmin } from "../../services/loginService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { performLogin } from "../../auth";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loginDetail, setLoginDetail] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event, field) => {
    const changedValue = event.target.value;
    setLoginDetail({
      ...loginDetail,
      [field]: changedValue,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      await toast.promise(loginAdmin(loginDetail), {
        pending: "Logging in...",
        success: {
          render({ data }) {
            if (data.status === 200) {
              performLogin(data, () => {
                navigate("/");
              });
              return data.message;
            } else {
              throw new Error(data.response.data.message);
            }
          },
        },
      });
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    }
  };

  const isFormValid = loginDetail.email && loginDetail.password;

  return (
    <div className="loginContainer">
      <div className="loginContents">
        <div className="loginContentsRight">
          <div className="logoContainer">
            <img src={logo} alt="logo" className="companyLogo" />
          </div>

          <div className="headerContainer">
            <span className="desc">Please sign in to continue</span>
          </div>

          <div className="loginForm">
            <form onSubmit={handleFormSubmit}>
              <div className="inputField">
                <MdOutlineEmail className="inputFieldIcon" />
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  className="emailField"
                  value={loginDetail.email}
                  onChange={(e) => handleChange(e, "email")}
                />
              </div>

              <div className="inputField">
                <MdOutlinePassword className="inputFieldIcon" />
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="Enter your password"
                  className="passwordField"
                  value={loginDetail.password}
                  onChange={(e) => handleChange(e, "password")}
                />
                <span
                  className="passwordToggleIcon"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>

              <div className="loginButton">
                <button
                  type="submit"
                  className="submitButton"
                  onClick={handleFormSubmit}
                  disabled={!isFormValid}
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" reverseOrder={true} />
    </div>
  );
};

export default Login;
