// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.optionsMenuContainer {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 100;
  margin-top: 10px;
  left: 0px;
}
.optionsMenuContainer::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 25%;
  transform: translateX(-50%);
  border-width: 0 10px 10px 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
.optionsMenuContainer .optionsMenuContents {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100px;
  justify-content: center;
  padding: 10px 20px;
}
.optionsMenuContainer .optionsMenuContents .menuListItem a {
  text-decoration: none;
  color: #363949;
  font-size: 12px;
  font-weight: 600;
}
.optionsMenuContainer .optionsMenuContents .menuListItem a:hover {
  color: #2384b4;
}`, "",{"version":3,"sources":["webpack://./src/components/OptionsMenu/OptionsMenu.scss"],"names":[],"mappings":"AAOA;EACI,kBAAA;EACA,uBAAA;EACA,sBAAA;EACA,yCAAA;EACA,YAAA;EACA,gBAAA;EACA,SAAA;AANJ;AAOI;EACI,WAAA;EACA,kBAAA;EACA,UAAA;EACA,SAAA;EACA,2BAAA;EACA,8BAAA;EACA,mBAAA;EACA,uDAAA;AALR;AAOI;EACI,aAAA;EACA,sBAAA;EACA,QAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;AALR;AAOQ;EACI,qBAAA;EACA,cA/BC;EAgCD,eAAA;EACA,gBAAA;AALZ;AAOY;EACI,cAnCH;AA8Bb","sourcesContent":["//colors\r\n$bg-color: #ffffff;\r\n$primary-color: #00425a;\r\n$color-small: rgba(128, 128, 128, 0.915);\r\n$color-dark: #363949;\r\n$color-icon: #2384b4;\r\n$border-color: rgba(128, 128, 128, 0.378);\r\n.optionsMenuContainer {\r\n    position: absolute;\r\n    background-color: white;\r\n    border: 1px solid #ccc;\r\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);\r\n    z-index: 100;\r\n    margin-top: 10px; // Adjust this to add some space for the arrow\r\n    left: 0px;\r\n    &::before {\r\n        content: \"\";\r\n        position: absolute;\r\n        top: -10px; // Position above the container\r\n        left: 25%; // Center it horizontally\r\n        transform: translateX(-50%);\r\n        border-width: 0 10px 10px 10px; // Widths for left, top, right, bottom\r\n        border-style: solid;\r\n        border-color: transparent transparent white transparent; // Only bottom border is colored\r\n    }\r\n    .optionsMenuContents {\r\n        display: flex;\r\n        flex-direction: column;\r\n        gap: 5px;\r\n        width: 100px;\r\n        justify-content: center;\r\n        padding: 10px 20px;\r\n\r\n        .menuListItem a {\r\n            text-decoration: none;\r\n            color: $color-dark;\r\n            font-size: 12px;\r\n            font-weight: 600;\r\n            \r\n            &:hover {\r\n                color: $color-icon;\r\n            }\r\n        }\r\n    }\r\n}\r\n\r\n\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
