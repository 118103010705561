// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardContainer {
  background-color: #ffffff;
  padding: 1.8rem;
  margin-top: 1rem;
  transition: all 300ms ease;
  height: 180px;
  width: 180px;
  z-index: 6;
  transition: 300ms all ease-in-out;
  border: 1px solid rgba(128, 128, 128, 0.5);
}
.cardContainer:hover {
  cursor: pointer;
}
.cardContainer .card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  transition: 300ms all ease-in-out;
}
.cardContainer .card:hover {
  cursor: pointer;
}
.cardContainer .card:hover .icon,
.cardContainer .card:hover .title {
  color: #264796;
}
.cardContainer .card .icon {
  color: #363949;
  border-radius: 5px;
  padding: 8px;
  font-size: 100px;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardContainer .card .title {
  font-weight: 600;
  font-size: 15px;
  color: #363949;
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/components/Card/Card.scss"],"names":[],"mappings":"AASA;EACE,yBARY;EASZ,eAPa;EAQb,gBAAA;EACA,0BAAA;EACA,aAAA;EACA,YAAA;EACA,UAAA;EACA,iCAAA;EACA,0CAAA;AARF;AASE;EACE,eAAA;AAPJ;AASE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;EACA,iCAAA;AAPJ;AAQI;EACE,eAAA;AANN;AAOM;;EAEE,cAhCQ;AA2BhB;AAQI;EACE,cA9BO;EA+BP,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AANN;AAQI;EACE,gBAAA;EACA,eAAA;EACA,cA3CO;EA4CP,0BAAA;AANN","sourcesContent":["//colors\r\n$primary-color: #264796;\r\n$color-white: #ffffff;\r\n$color-light: rgba(132, 139, 200, 0.18);\r\n$card-padding: 1.8rem;\r\n$card-border-radius: 5px;\r\n$color-red: #d62828;\r\n$color-dark: #363949;\r\n$color-icon: #2384b4;\r\n.cardContainer {\r\n  background-color: $color-white;\r\n  padding: $card-padding;\r\n  margin-top: 1rem;\r\n  transition: all 300ms ease;\r\n  height: 180px;\r\n  width: 180px;\r\n  z-index: 6;\r\n  transition: 300ms all ease-in-out;\r\n  border: 1px solid rgba(128, 128, 128, 0.5);\r\n  &:hover {\r\n    cursor: pointer;\r\n  }\r\n  .card {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    flex-direction: column;\r\n    gap: 1.5rem;\r\n    transition: 300ms all ease-in-out;\r\n    &:hover {\r\n      cursor: pointer;\r\n      .icon,\r\n      .title {\r\n        color: $primary-color;\r\n      }\r\n    }\r\n    .icon {\r\n      color: $color-dark;\r\n      border-radius: 5px;\r\n      padding: 8px;\r\n      font-size: 100px;\r\n      height: 100px;\r\n      width: 100px;\r\n      display: flex;\r\n      align-items: center;\r\n      justify-content: center;\r\n    }\r\n    .title {\r\n      font-weight: 600;\r\n      font-size: 15px;\r\n      color: $color-dark;\r\n      text-transform: capitalize;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
