import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../utils/config";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import NotFound from "../../../components/NotFound/NotFound";
import Loader from "../../../components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import List from "../../../components/List/List";

const RoutineList = () => {
  const headers = [
    "Subject",
    "Program",
    "Semester",
    "Day",
    "Start Time",
    "End Time",
    "Room",
    "Faculty",
  ];
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await adminRequest.get(`${BASE_URL}/routine/list`);
      const fetchedRows = response.data.data.map((r) => ({
        displayData: [
          r.subject,
          r.program,
          r.semester,
          r.day,
          r.startTime,
          r.endTime,
          r.room,
          r.faculty.name,
        ],
        uuid: r.uuid,
      }));
      setRows(fetchedRows);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  updateAuthToken();

  const getMenuItems = (row) => [{ link: `edit/${row.uuid}`, text: "Edit" }];

  return (
    <div className="routineListContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <List
            title="Routine List"
            createButtonLabel="Create Routine"
            headers={headers}
            rows={rows.map((row) => row.displayData)}
            link="create"
            showEyeViewIcon={false}
            showFilterIcon={false}
            getMenuItems={(row) =>
              getMenuItems(rows.find((r) => r.displayData === row))
            }
          />
        </>
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default RoutineList;
