import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { PiStudent } from "react-icons/pi";
import { PiChalkboardTeacher } from "react-icons/pi";
import { LuBookMinus } from "react-icons/lu";
import { BASE_URL } from "../../utils/config";
import { adminRequest } from "../../utils/requestMethods";
import { FaPlus } from "react-icons/fa";

const Dashboard = () => {
  const [notices, setNotices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchNotice = async () => {
    setIsLoading(true);
    try {
      const response = await adminRequest.get(`${BASE_URL}/notice/list`);
      const fetchedRows = response.data.notices.map((n) => ({
        displayData: [
          n.title,
          n.date,
          new Date(n.createdAt).toLocaleDateString(),
          n.status,
        ],
        title: n.title,
        date: n.date,
        createdAt: new Date(n.createdAt).toLocaleDateString(),
        status: n.status,
      }));
      setNotices(fetchedRows);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchNotice();
  }, []);

  return (
    <div className="dashboardContainer">
      <div className="left">
        <div className="heading">Dashboard</div>
        <div className="boxContainer">
          <div className="studentBox">
            <span className="studentIcon">
              <PiStudent />
            </span>
            <span className="title">Total Students</span>
            <span className="value">560</span>
          </div>
          <div className="facultyBox">
            <span className="facultyIcon">
              <PiChalkboardTeacher />
            </span>
            <span className="title">Total Faculties</span>
            <span className="value">830</span>
          </div>
          <div className="courseBox">
            <span className="courseIcon">
              <LuBookMinus />
            </span>
            <span className="title">Total Courses</span>
            <span className="value">790</span>
          </div>
        </div>
        <div className="noticeContainer">
          <div className="noticeHeader">Recent Notices</div>
          <div className="noticeBody">
            <table className="subjectInputGroup">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Date</th>
                  <th>Created Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {notices.map((notice, index) => (
                  <tr key={index}>
                    <td>
                      {notice.title.length > 30
                        ? `${notice.title.slice(0, 30)}...`
                        : notice.title}
                    </td>
                    <td>{notice.date}</td>
                    <td>{new Date(notice.createdAt).toLocaleDateString()}</td>
                    <td>{notice.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="header">College Analytics</div>
        <div className="boxContainer">
          <div className="studentBox">
            <span className="studentIcon">
              <PiStudent />
            </span>
            <span className="headingContainer">
              <span className="title">New Students</span>
              <span className="description">Academic year 2024</span>
            </span>
            <span className="percentage">+15%</span>
            <span className="data">80</span>
          </div>
          <div className="teacherBox">
            <span className="teacherIcon">
              <PiChalkboardTeacher />
            </span>
            <span className="headingContainer">
              <span className="title">Total Teachers</span>
              <span className="description">Academic year 2024</span>
            </span>
            <span className="percentage">+18%</span>
            <span className="data">12</span>
          </div>
          <div className="addButton">
            <span className="icon">
              <FaPlus />
            </span>
            <span className="title">Add Analytics</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
