import React, { useEffect, useState } from "react";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import "./CreateResult.scss";
import { BASE_URL } from "../../../utils/config";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { FaArrowLeftLong } from "react-icons/fa6";

const CreateResult = () => {
  const initialFormData = {
    exam: "",
    course: "",
    semester: "",
    student: "",
    subjects: [],
  };

  const [subjects, setSubjects] = useState([]);
  const [courses, setCourses] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [students, setStudents] = useState([]);
  const [exams, setExams] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleMarksChange = (e, index) => {
    const updatedSubjects = [...formData.subjects];
    updatedSubjects[index] = {
      ...updatedSubjects[index],
      obtainedMarks: e.target.value,
    };
    setFormData({ ...formData, subjects: updatedSubjects });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      examUuid: formData.exam,
      course: formData.course,
      semesterUuid: formData.semester,
      studentUuid: formData.student,
      subjects: formData.subjects,
    };

    try {
      const response = await adminRequest.post(
        `${BASE_URL}/result/create`,
        requestData
      );
      toast.success(response.data.message);

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/course/getNameAndId`
        );
        setCourses(response.data.data);
        updateAuthToken();
      } catch (error) {}
    };
    fetchCourse();
  }, []);

  useEffect(() => {
    if (formData.course) {
      const fetchSemester = async () => {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/course/getSemestersById`,
            { id: formData.course }
          );
          setSemesters(response.data.data);
          updateAuthToken();
        } catch (error) {}
      };
      fetchSemester();
    }
  }, [formData.course]);

  useEffect(() => {
    if (formData.semester) {
      const fetchStudent = async () => {
        try {
          const response = await adminRequest.post(`${BASE_URL}/student/list`, {
            uuid: formData.semester,
          });
          setStudents(response.data.data);
          updateAuthToken();
        } catch (error) {}
      };

      const fetchSubject = async () => {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/course/list/subject`,
            {
              uuid: formData.semester,
            }
          );
          setSubjects(response.data.data);
          setFormData((prevState) => ({
            ...prevState,
            subjects: response.data.data.map((subject) => ({
              name: subject.name,
              obtainedMarks: "",
            })),
          }));
          updateAuthToken();
        } catch (error) {}
      };

      fetchStudent();
      fetchSubject();
    }
  }, [formData.semester]);

  useEffect(() => {
    const fetchExam = async () => {
      try {
        const response = await adminRequest.get(`${BASE_URL}/exam/list`);
        setExams(response.data.data);
        updateAuthToken();
      } catch (error) {}
    };
    fetchExam();
  }, []);

  return (
    <div className="createResultContainer">
      <div className="top">
        <span className="backIcon" onClick={handleBackClick}>
          <FaArrowLeftLong />
        </span>
        <span className="headerTitle">Create Result</span>
      </div>
      <div className="bottom">
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="studentWrapper">
            <div className="inputGroup">
              <label htmlFor="exams">Exam</label>
              <select name="exam" value={formData.exam} onChange={handleChange}>
                <option value="" disabled>
                  Select Exam
                </option>
                {exams.map((option, index) => (
                  <option value={option.uuid} key={index}>
                    {option.name} ({option.examYear})
                  </option>
                ))}
              </select>
            </div>
            <div className="inputGroup">
              <label htmlFor="courses">Course</label>
              <select
                name="course"
                value={formData.course}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select Course
                </option>
                {courses.map((option, index) => (
                  <option value={option.id} key={index}>
                    {option.short_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="inputGroup">
              <label htmlFor="semesters">Semester</label>
              <select
                name="semester"
                value={formData.semester}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select Semester
                </option>
                {semesters.map((option, index) => (
                  <option value={option.uuid} key={index}>
                    {option.semester}
                  </option>
                ))}
              </select>
            </div>
            <div className="inputGroup">
              <label htmlFor="student">Student</label>
              <select
                name="student"
                value={formData.student}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select Student
                </option>
                {students.map((option, index) => (
                  <option value={option.uuid} key={index}>
                    {option.fullName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {formData.semester && formData.student && (
            <div className="marksWrapper">
              <span>Marks Ledger</span>
              <table border={1} className="subjectInputGroup">
                <tbody>
                  {subjects.map((subject, index) => (
                    <tr key={index}>
                      <td>
                        <label>{subject.name}</label>
                      </td>
                      <td>
                        <input
                          type="number"
                          value={subject.obtainedMarks}
                          onChange={(e) => handleMarksChange(e, index)}
                          placeholder="Obtained Marks"
                          min={0}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </form>
        <div className="submitWrapper">
          <button type="submit" className="create-btn" onClick={handleSubmit}>
            Submit
          </button>
          <button
            type="submit"
            className="cancel-btn "
            onClick={handleBackClick}
          >
            Cancel
          </button>
        </div>
      </div>

      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateResult;
