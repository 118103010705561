import React, { useState } from "react";
import "./Course.scss";
import Card from "../../components/Card/Card";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { IoBookOutline } from "react-icons/io5";
import { GiBookshelf } from "react-icons/gi";
import { MdOutlineCastForEducation } from "react-icons/md";
import { FaArrowLeftLong } from "react-icons/fa6";

const iconMapping = {
  IoBookOutline,
  GiBookshelf,
  MdOutlineCastForEducation,
};

const Course = () => {
  const navigate = useNavigate();
  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const [course] = useState({
    navbar: [
      {
        name: "Course",
        link: "/courses",
        icon: "GiBookshelf",
        parent_name: "Courses",
      },
      {
        name: "Semester",
        link: "/semester",
        icon: "MdOutlineCastForEducation",
        parent_name: "Courses",
      },
      {
        name: "Subject",
        link: "/subject",
        icon: "IoBookOutline",
        parent_name: "Courses",
      },
    ],
  });

  return (
    <div className="coursePageContainer">
      <div className="coursePageContents">
        <div className="headerTitle">
          <FaArrowLeftLong className="backIcon" onClick={handleBackClick} />
          <span className="title"> Courses</span>
        </div>
        <div className="courseCard">
          {course.navbar.map((item, index) => {
            const IconComponent = iconMapping[item.icon];
            return (
              <Link
                to={`${item.link}`}
                style={{ textDecoration: "none" }}
                key={index}
              >
                <Card
                  icon={IconComponent ? <IconComponent /> : null}
                  title={item.name}
                />
              </Link>
            );
          })}
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Course;
