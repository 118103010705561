import React from "react";
import "./Homepage.scss";
import { Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Dashboard from "../Dashboard/Dashboard";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import UserList from "../User/UserList/UserList";
import CreateUser from "../User/CreateUser/CreateUser";
import UserProfile from "../../components/UserProfile/UserProfile";
import Settings from "../Settings/Settings";
import NoticeList from "../Notice/NoticeList/NoticeList";
import CreateNotice from "../Notice/CreateNotice/CreateNotice";
import ViewNotice from "../Notice/ViewNotice/ViewNotice";
import CourseList from "../Course/CourseList/CourseList";
import CreateCourse from "../Course/CreateCourse/CreateCourse";
import TestimonialList from "../Testimonial/TestimonialList/TestimonialList";
import CreateTestimonial from "../Testimonial/CreateTestimonial/CreateTestimonial";
import FacultyList from "../Faculty/FacultyList/FacultyList";
import CreateFaculty from "../Faculty/CreateFaculty/CreateFaculty";
import EditFaculty from "../Faculty/EditFaculty/EditFaculty";
import ViewFaculty from "../Faculty/ViewFaculty/ViewFaculty";
import EditUser from "../User/EditUser/EditUser";
import UserDetail from "../User/UserDetail/UserDetail";
import ResourceList from "../Resource/ResourceList/ResourceList";
import CreateResource from "../Resource/CreateResource/CreateResource";
import EditTestimonial from "../Testimonial/EditTestimonial/EditTestimonial";
import ViewTestimonial from "../Testimonial/ViewTestimonial/ViewTestimonial";
import ChangePassword from "../ChangePassword/ChangePassword";
import NewsList from "../News/NewsList/NewsList";
import CreateNews from "../News/CreateNews/CreateNews";
import EditNews from "../News/EditNews/EditNews";
import SiteContent from "../Site/SiteContent/SiteContent";
import About from "../Site/About/About";
import Mission from "../Site/Mission/Mission";
import Vision from "../Site/Vision/Vision";
import PrincipalMessage from "../Site/PrincipalMessage/PrincipalMessage";
import DirectorMessage from "../Site/DirectorMessage/DirectorMessage";
import GalleryList from "../Gallery/GalleryList/GalleryList";
import CreateGallery from "../Gallery/CreateGallery/CreateGallery";
import ViewGallery from "../Gallery/ViewGallery/ViewGallery";
import Course from "../Course/Course";
import EventList from "../Event/EventList/EventList";
import CreateEvent from "../Event/CreateEvent/CreateEvent";
import ViewEvent from "../Event/ViewEvent/ViewEvent";
import CreateStudent from "../Student/CreateStudent/CreateStudent";
import CreateSemester from "../Semester/CreateSemester/CreateSemester";
import CreateSubject from "../Subject/CreateSubject/CreateSubject";
import Program from "../Program/Program";
import SemesterList from "../Semester/SemesterList/SemesterList";
import CreateRoutine from "../Routine/CreateRoutine/CreateRoutine";
import RoutineList from "../Routine/RoutineList/RoutineList";
import EditCourse from "../Course/EditCourse/EditCourse";
import EditRoutine from "../Routine/EditRoutine/EditRoutine";
import Setup from "../Setup/Setup";
import StudentList from "../Student/StudentList/StudentList";
import ViewStudent from "../Student/ViewStudent/ViewStudent";
import CreateExam from "../Exam/CreateExam/CreateExam";
import ExamList from "../Exam/ExamList/ExamList";
import CreateResult from "../Result/CreateResult/CreateResult";
import EditExam from "../Exam/EditExam/EditExam";
import ResultList from "../Result/ResultList/ResultList";
import EditResult from "../Result/EditResult/EditResult";
import ViewCourse from "../Course/ViewCourse/ViewCourse";
import SubjectList from "../Subject/SubjectList/SubjectList";
import EditSubject from "../Subject/EditSubject/EditSubject";

const Homepage = () => {
  return (
    <div className="homepageContainer">
      <div className="homepageContents">
        <div className="homepageTop">
          <div className="navbarArea">
            <Navbar />
          </div>
        </div>
        <div className="middle">
          <div className="homepageContents_left">
            <div className="sidebarArea">
              <Sidebar />
            </div>
          </div>
          <div className="homepageContents_right">
            <Routes>
              <Route path="profile" element={<UserProfile />} />
              <Route path="/" element={<Dashboard />} />
              <Route path="adminUser" element={<UserList />} />
              <Route path="adminUser/create" element={<CreateUser />} />
              <Route path="adminUser/edit/:uuid" element={<EditUser />} />
              <Route path="/adminUser/view/:uuid" element={<UserDetail />} />
              <Route path="/changePassword" element={<ChangePassword />} />
              <Route path="settings" element={<Settings />} />
              <Route path="siteManagement" element={<SiteContent />} />
              <Route path="notice" element={<NoticeList />} />
              <Route path="notice/create" element={<CreateNotice />} />
              <Route path="/notice/view/:uuid" element={<ViewNotice />} />
              <Route path="course" element={<Course />} />
              <Route path="courses" element={<CourseList />} />
              <Route path="courses/create" element={<CreateCourse />} />
              <Route path="/courses/view/:uuid" element={<ViewCourse />} />
              <Route path="courses/edit/:uuid" element={<EditCourse />} />
              <Route path="faculty" element={<FacultyList />} />
              <Route path="faculty/create" element={<CreateFaculty />} />
              <Route path="faculty/edit/:uuid" element={<EditFaculty />} />
              <Route path="faculty/view/:uuid" element={<ViewFaculty />} />
              <Route path="student" element={<StudentList />} />
              <Route path="testimonials" element={<TestimonialList />} />
              <Route
                path="testimonials/create"
                element={<CreateTestimonial />}
              />
              <Route
                path="testimonials/edit/:uuid"
                element={<EditTestimonial />}
              />
              <Route
                path="/testimonials/view/:uuid"
                element={<ViewTestimonial />}
              />
              <Route path="resources" element={<ResourceList />} />
              <Route path="resources/create" element={<CreateResource />} />
              <Route path="news" element={<NewsList />} />
              <Route path="news/create" element={<CreateNews />} />
              <Route path="news/edit/:slug" element={<EditNews />} />
              <Route path="about" element={<About />} />
              <Route path="mission" element={<Mission />} />
              <Route path="vision" element={<Vision />} />
              <Route path="principalMessage" element={<PrincipalMessage />} />
              <Route path="directorMessage" element={<DirectorMessage />} />
              <Route path="gallery" element={<GalleryList />} />
              <Route path="gallery/create" element={<CreateGallery />} />
              <Route path="/gallery/view/:slug" element={<ViewGallery />} />
              <Route path="event" element={<EventList />} />
              <Route path="event/create" element={<CreateEvent />} />
              <Route path="/event/view/:slug" element={<ViewEvent />} />
              <Route path="student/create" element={<CreateStudent />} />
              <Route path="/student/view/:uuid" element={<ViewStudent />} />
              <Route path="routine" element={<RoutineList />} />
              <Route path="routine/create" element={<CreateRoutine />} />
              <Route path="routine/edit/:uuid" element={<EditRoutine />} />
              <Route path="subject" element={<Program />} />
              <Route path="subject/:uuid/create" element={<CreateSubject />} />
              <Route path="/subject/:uuid" element={<SubjectList />} />
              <Route
                path="/subject/:uuid/edit/:uuid"
                element={<EditSubject />}
              />
              <Route path="semester" element={<Program />} />
              <Route path="/semester/:uuid" element={<SemesterList />} />
              <Route
                path="semester/:uuid/create"
                element={<CreateSemester />}
              />

              <Route path="setup" element={<Setup />} />

              <Route path="exam" element={<ExamList />} />
              <Route path="exam/create" element={<CreateExam />} />
              <Route path="exam/edit/:uuid" element={<EditExam />} />
              <Route path="result/create" element={<CreateResult />} />
              <Route path="result" element={<ResultList />} />
              <Route path="result/edit/:uuid" element={<EditResult />} />
            </Routes>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Homepage;
