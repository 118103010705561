// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebarMenu {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  transition: 300ms all ease-in-out;
}
.sidebarMenu.active {
  background-color: rgba(237, 242, 244, 0.8352941176);
  border-right: 2px solid #264796;
}
.sidebarMenu.active .sidebarIcon,
.sidebarMenu.active .title {
  color: #264796;
}
.sidebarMenu .menuContent {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(32.3149606299, 34.1102362205, 43.6850393701);
  transition: 300ms all ease-in-out;
}
.sidebarMenu .menuContent .sidebarIcon {
  font-size: 20px;
  margin-bottom: -10px;
}
.sidebarMenu .menuContent .title {
  font-size: 11px;
  font-weight: 600;
  text-align: center;
}
.sidebarMenu .menuContent:hover {
  color: rgb(30.8488372093, 116.3441860465, 158.6511627907);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/SidebarMenu/SidebarMenu.scss"],"names":[],"mappings":"AAOA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,iCAAA;AANF;AAOE;EACE,mDATc;EAUd,+BAAA;AALJ;AAMI;;EAEE,cAjBU;AAahB;AAOE;EACE,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,uDAAA;EACA,iCAAA;AALJ;AAMI;EACE,eAAA;EACA,oBAAA;AAJN;AAMI;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;AAJN;AAMI;EACE,yDAAA;EACA,eAAA;AAJN","sourcesContent":["//colors\r\n$bg-color: #ffffff;\r\n$primary-color: #264796;\r\n$color-small: rgba(128, 128, 128, 0.915);\r\n$color-dark: #363949;\r\n$color-icon: #2384b4;\r\n$active-bg-color: #edf2f4d5;\r\n.sidebarMenu {\r\n  height: 35px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  padding: 10px 10px;\r\n  transition: 300ms all ease-in-out;\r\n  &.active {\r\n    background-color: $active-bg-color;\r\n    border-right: 2px solid $primary-color;\r\n    .sidebarIcon,\r\n    .title {\r\n      color: $primary-color;\r\n    }\r\n  }\r\n  .menuContent {\r\n    padding-top: 10px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    color: darken($color-dark, 10%);\r\n    transition: 300ms all ease-in-out;\r\n    .sidebarIcon {\r\n      font-size: 20px;\r\n      margin-bottom: -10px;\r\n    }\r\n    .title {\r\n      font-size: 11px;\r\n      font-weight: 600;\r\n      text-align: center;\r\n    }\r\n    &:hover {\r\n      color: darken($color-icon, 5%);\r\n      cursor: pointer;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
