import React, { useEffect, useState } from "react";
import "./Sidebar.scss";
import { IoHomeOutline } from "react-icons/io5";
import { PiSignOut } from "react-icons/pi";
import SidebarMenu from "../SidebarMenu/SidebarMenu";
import { performLogout } from "../../auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaRegNewspaper } from "react-icons/fa";
import { BsPeople } from "react-icons/bs";
import { TfiGallery } from "react-icons/tfi";
import { TfiAnnouncement } from "react-icons/tfi";
import { IoSettingsOutline } from "react-icons/io5";
import { PiChalkboardTeacher } from "react-icons/pi";
import { PiStudent } from "react-icons/pi";
import { BsMenuApp } from "react-icons/bs";
import {} from "react-icons/pi";
import { FaBook } from "react-icons/fa";
import { BASE_URL } from "../../utils/config";
import { adminRequest, updateAuthToken } from "../../utils/requestMethods";

const iconMapping = {
  IoHomeOutline,
  PiChalkboardTeacher,
  PiSignOut,
  BsPeople,
  TfiAnnouncement,
  FaBook,
  TfiGallery,
  IoSettingsOutline,
  FaRegNewspaper,
  PiStudent,
  BsMenuApp,
};

const Sidebar = () => {
  const navigate = useNavigate();
  const [navigation, setNavigation] = useState([]);
  const location = useLocation();
  const activeURL = location.pathname.split("/")[1];
  const handleSignout = () => {
    performLogout(() => {
      toast.success("Logging Out");
      navigate("/login");
    });
  };
  useEffect(() => {
    let isMounted = true;
    const fetchNavigation = async () => {
      try {
        const response = await adminRequest.get(`${BASE_URL}/navbar`);
        if (isMounted) {
          updateAuthToken();
          setNavigation(response.data);
        }
      } catch (error) {
        if (isMounted) {
          console.log("Failed to fetch navigation");
        }
      }
    };
    fetchNavigation();
    return () => {
      isMounted = false;
    };
  }, []);
  if (!navigation) return <div>Loading...</div>;
  if (!navigation || !navigation.navbar) return <div>No data available</div>;

  return (
    <div className="sidebarContainer">
      <div className="sidebarContents">
        <div className="sidebarMenuContainer">
          {navigation.navbar.map((item, index) => {
            const IconComponent = iconMapping[item.icon];
            const normalizedNavigation = item.link.replace(/^\//, "");
            return (
              <Link
                to={`${item.link}`}
                style={{ textDecoration: "none" }}
                key={index}
              >
                <SidebarMenu
                  icon={IconComponent ? <IconComponent /> : null}
                  title={item.name}
                  isActive={activeURL === normalizedNavigation}
                />
              </Link>
            );
          })}
          <SidebarMenu
            icon={<PiSignOut />}
            title="Sign Out"
            onClick={handleSignout}
          />
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Sidebar;
