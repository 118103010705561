import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NotFound from "../../../components/NotFound/NotFound";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../components/Loader/Loader";

const EditExam = () => {
  const initialFormData = {
    name: "",
    examYear: "",
  };
  const navigate = useNavigate();
  const location = useLocation();
  const activeURL = location.pathname.split("/")[3];
  const [formData, setFormData] = useState(initialFormData);
  const [data, setData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchExam = async () => {
      try {
        const response = await adminRequest.post(`${BASE_URL}/exam/viewOne`, {
          uuid: `${activeURL}`,
        });
        setData(response.data);
        setFormData({
          name: response.data.data.name,
          examYear: response.data.data.examYear,
        });
      } catch (error) {
        <NotFound />;
      }
    };
    fetchExam();
  }, [activeURL]);

  updateAuthToken();

  if (!data || !data.data) {
    return <Loader />;
  }
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/exam/update`, {
          uuid: activeURL,
          name: formData.name,
          examYear: formData.examYear,
        }),
        {
          pending: "Updating Exam",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fields = [
    {
      name: "name",
      label: "Name",
      type: "text",
      value: formData.name,
      onChange: handleChange,
    },

    {
      name: "examYear",
      label: "Exam Year",
      type: "text",
      value: formData.examYear,
      onChange: handleChange,
    },
  ];

  return (
    <div className="editExamContainer">
      <CustomForm
        header="Edit Exam"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Update"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EditExam;
