// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notFoundContainer .notFoundContents {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;
  width: 100%;
  gap: 20px;
}
.notFoundContainer .notFoundContents .notFoundIconContainer {
  border-radius: 50%;
  background-color: rgba(237, 242, 244, 0.8352941176);
}
.notFoundContainer .notFoundContents .notFoundIconContainer .notFoundIcon {
  padding: 5px;
  height: 80px;
  width: 80px;
  color: #00425a;
}
.notFoundContainer .notFoundContents .notFoundText {
  color: #363949;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/NotFound/NotFound.scss"],"names":[],"mappings":"AAQI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;AAPR;AAQQ;EACI,kBAAA;EACA,mDAZM;AAMlB;AAOY;EACI,YAAA;EACA,YAAA;EACA,WAAA;EACA,cArBA;AAgBhB;AAQQ;EACI,cAvBC;EAwBD,gBAAA;AANZ","sourcesContent":["//colors\r\n$bg-color: #ffffff;\r\n$primary-color: #00425a;\r\n$color-small: rgba(128, 128, 128, 0.915);\r\n$color-dark: #363949;\r\n$color-icon: #2384b4;\r\n$active-bg-color: #edf2f4d5;\r\n.notFoundContainer{\r\n    .notFoundContents{\r\n        display: flex;\r\n        justify-content: center;\r\n        align-items: center;\r\n        flex-direction: column;\r\n        height:80vh;\r\n        width: 100%;  \r\n        gap: 20px;\r\n        .notFoundIconContainer{\r\n            border-radius: 50%;\r\n            background-color: $active-bg-color;\r\n            .notFoundIcon{\r\n                padding: 5px;\r\n                height:80px;\r\n                width:80px;\r\n                color: $primary-color;\r\n            }\r\n        }\r\n        .notFoundText{\r\n            color: $color-dark;\r\n            font-weight: 500;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
