import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import "./CreateSubject.scss";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
const CreateSubject = () => {
  const initialFormData = {
    course_uuid: "",
    curriculum_id: "",
  };
  const initialSubjectDetails = {
    subject_name: "",
    credit_hour: "",
    subject_code: "",
    subject_description: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [subjectData, setSubjectData] = useState([initialSubjectDetails]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [semester, setSemester] = useState([]);
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/course/getNameAndId`
        );
        setCourses(response.data.data);
        updateAuthToken();
      } catch (error) {}
    };
    fetchCourse();
  }, []);

  useEffect(() => {
    if (formData.course_uuid) {
      const fetchSemester = async () => {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/course/getSemesters`,
            {
              uuid: formData.course_uuid,
            }
          );
          setSemester(response.data.data);
          updateAuthToken();
        } catch (error) {}
      };
      fetchSemester();
    }
  }, [formData.course_uuid]);

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubjectChange = (index, field, value) => {
    const newSubjects = [...subjectData];
    newSubjects[index][field] = value;
    setSubjectData(newSubjects);
  };

  const addSubjectFields = () => {
    setSubjectData([...subjectData, { ...initialSubjectDetails }]);
  };
  const removeSubjectFields = (index) => {
    const newSubjectData = [...subjectData];
    newSubjectData.splice(index, 1);
    setSubjectData(newSubjectData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      let subjectsData = subjectData.map((subject) => ({
        subject_name: subject.subject_name,
        credit_hour: subject.credit_hour,
        subject_code: subject.subject_code,
        subject_description: subject.subject_description,
        curriculum_id: formData.curriculum_id,
      }));

      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/course/subject/create`, {
          course_uuid: formData.course_uuid,
          curriculum_id: formData.curriculum_id,
          subjects: subjectsData,
        }),
        {
          pending: "Processing your request...",
        }
      );

      toast.success(response.data.message);

      setFormData(initialFormData);
      setSubjectData([initialSubjectDetails]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="createSubjectContainer">
      <div className="top">
        <span className="backIcon" onClick={handleBackClick}>
          <FaArrowLeftLong />
        </span>
        <span className="headerTitle">Create Subject</span>
      </div>
      <div className="bottom">
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="courseWrapper">
            <div className="inputGroup">
              <label htmlFor="courses">Course</label>
              <select
                name="course_uuid"
                value={formData.course_uuid}
                onChange={handleFormChange}
              >
                <option value="" disabled>
                  Select Course
                </option>
                {courses.map((option, index) => (
                  <option value={option.uuid} key={index}>
                    {option.short_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="inputGroup">
              <label htmlFor="semesters">Semester</label>
              <select
                name="curriculum_id"
                value={formData.curriculum_id}
                onChange={handleFormChange}
              >
                <option value="" disabled>
                  Select Semester
                </option>
                {semester.map((option, index) => (
                  <option value={option.id} key={index}>
                    {option.semester}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="subjectFields">
            {subjectData.map((subject, index) => (
              <div key={index} className="subjectFieldGroup">
                <input
                  type="text"
                  placeholder="Subject Name"
                  value={subject.subject_name}
                  onChange={(e) =>
                    handleSubjectChange(index, "subject_name", e.target.value)
                  }
                />
                <input
                  type="text"
                  placeholder="Subject Description"
                  value={subject.subject_description}
                  onChange={(e) =>
                    handleSubjectChange(
                      index,
                      "subject_description",
                      e.target.value
                    )
                  }
                />
                <input
                  type="text"
                  placeholder="Credit Hour"
                  className="shortField"
                  value={subject.credit_hour}
                  onChange={(e) =>
                    handleSubjectChange(index, "credit_hour", e.target.value)
                  }
                />
                <input
                  type="text"
                  className="shortField"
                  placeholder="Subject Code"
                  value={subject.subject_code}
                  onChange={(e) =>
                    handleSubjectChange(index, "subject_code", e.target.value)
                  }
                />
                <div className="buttonGroup">
                  {index === subjectData.length - 1 && (
                    <button
                      type="button"
                      className="addSubjectButton"
                      onClick={addSubjectFields}
                    >
                      +
                    </button>
                  )}
                  {index === subjectData.length - 1 &&
                    subjectData.length > 1 && (
                      <button
                        type="button"
                        className="removeSubjectButton"
                        onClick={() => removeSubjectFields(index)}
                      >
                        -
                      </button>
                    )}
                </div>
              </div>
            ))}
          </div>
        </form>
        <div className="submitWrapper">
          <button type="submit" className="create-btn" onClick={handleSubmit}>
            Submit
          </button>
          <button
            type="submit"
            className="cancel-btn "
            onClick={handleBackClick}
          >
            Cancel
          </button>
        </div>
      </div>

      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateSubject;
