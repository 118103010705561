import React, { useEffect, useState } from "react";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import NotFound from "../../../components/NotFound/NotFound";
import Loader from "../../../components/Loader/Loader";
import List from "../../../components/List/List";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";

const NewsList = () => {
  const headers = ["Title", "Content", "Status", "Created Date"];

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await adminRequest.get(`${BASE_URL}/news/list`);
      const fetchedRows = response.data.news.map((e) => ({
        displayData: [
          e.title,
          e.content.length > 20 ? `${e.content.slice(0, 20)}...` : e.content,
          e.status,
          new Date(e.createdAt).toLocaleDateString(),
        ],
        uuid: e.uuid,
        slug: e.slug,
        status: e.status,
      }));
      setRows(fetchedRows);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleBlock = async (uuid) => {
    Swal.fire({
      title: "Are you sure you want to block this news?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Block",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(`${BASE_URL}/news/block`, {
            uuid: uuid,
          });

          Swal.fire({
            title: "Blocked!",
            text: `${response.data.message}`,
            icon: "success",
          });

          fetchData();
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };

  const handleUnblock = async (uuid) => {
    Swal.fire({
      title: "Are you sure you want to unblock this news?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Unblock",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(`${BASE_URL}/news/unblock`, {
            uuid: uuid,
          });
          Swal.fire({
            title: "Unblocked!",
            text: `${response.data.message}`,
            icon: "success",
          });
          fetchData();
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };

  updateAuthToken();

  const getMenuItems = (row) => [
    { link: `view/${row.slug}`, text: "View" },
    { link: `edit/${row.slug}`, text: "Edit" },
    row.status !== "BLOCKED"
      ? {
          link: "#",
          text: "Block",
          onClick: (e) => {
            e.preventDefault();
            handleBlock(row.uuid);
          },
        }
      : {
          link: "#",
          text: "Unblock",
          onClick: (e) => {
            e.preventDefault();
            handleUnblock(row.uuid);
          },
        },
  ];

  return (
    <div className="newsListContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <List
            title="News List"
            createButtonLabel="Create News"
            headers={headers}
            rows={rows.map((row) => row.displayData)}
            link="create"
            showEyeViewIcon={false}
            showFilterIcon={false}
            getMenuItems={(row) =>
              getMenuItems(rows.find((r) => r.displayData === row))
            }
          />
        </>
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default NewsList;
