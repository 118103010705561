import React, { useRef } from "react";
import "./CustomForm.scss";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
const CustomForm = ({
  header = "Default Header",
  fields = [],
  flexDirection = "column",
  createButtonLabel = "Create",
  onSubmit,
  showDefaultHeader = true,
  isSubmitting = false,
  showCancelButton = true,
  showCreateButton = true,
  showCloseButton = false,
  closeFilterForm,
}) => {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="createPageContainer">
      <div className="createPageContents">
        {showDefaultHeader && (
          <div className="top">
            <span className="backIcon" onClick={handleBackClick}>
              <FaArrowLeftLong />
            </span>
            <span className="headerTitle">{header}</span>
          </div>
        )}
        <div className="bottom">
          <form
            style={{ flexDirection: flexDirection }}
            onSubmit={onSubmit}
            encType="multipart/form-data"
          >
            {fields.map((field, index) => (
              <div className="inputGroup" key={index}>
                <label htmlFor={field.name}>{field.label}</label>
                {field.type === "select" ? (
                  <select
                    name={field.name}
                    id={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    style={{
                      width: field.width || "310px",
                      height: field.height || "35px",
                    }}
                  >
                    {field.options.map((option, index) => (
                      <option value={option.value} key={index}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                ) : field.type === "textarea" ? (
                  <textarea
                    name={field.name}
                    id={field.name}
                    value={field.value}
                    placeHolder={field.placeHolder}
                    onChange={field.onChange}
                    style={{
                      width: field.width || "300px",
                      height: field.height || "100px",
                    }}
                  />
                ) : field.type === "file" ? (
                  <div className="fileInput">
                    <input
                      type="file"
                      name={field.name}
                      id={field.name}
                      onChange={field.onChange}
                      multiple={field.multiple}
                    />
                  </div>
                ) : (
                  <input
                    type={field.type}
                    name={field.name}
                    id={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={field.isDisabled}
                    style={{
                      width: field.width || "300px",
                      height: field.height || "25px",
                    }}
                  />
                )}
                <small className="tailText">{field.tail}</small>
              </div>
            ))}
            <div className="btn-group">
              {showCreateButton && (
                <button
                  type="submit"
                  className="create-btn"
                  disabled={isSubmitting}
                >
                  {createButtonLabel}
                </button>
              )}
              {showCancelButton && (
                <button
                  type="button"
                  className="cancel-btn"
                  disabled={isSubmitting}
                  onClick={handleBackClick}
                >
                  Cancel
                </button>
              )}
              {showCloseButton && (
                <button
                  type="button"
                  className="cancel-btn"
                  disabled={isSubmitting}
                  onClick={closeFilterForm}
                >
                  Reset
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomForm;
